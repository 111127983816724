import { api } from '../axios'
const userType = localStorage.getItem('userType')

const getUserExtension = async () => {
  const res = await api.get('extension/agent-info')
  let agent = res.data.agent

  return agent
}

const getCallDetails = async id => {
  const res = await api.get(`auto-dialer-channels/${userType}/${id}`)
  return res.data
}

const saveCallNotes = async (id, notes) => {
  const res = await api.patch(`auto-dialer-channels/${userType}/${id}/notes`, {
    notes
  })
  return res.data
}

export default {
  getUserExtension,
  getCallDetails,
  saveCallNotes
}
