export const yupToKV = yupError => {
  let error = {}

  yupError.inner.forEach(err => {
    const path = err.path.replaceAll('"', '')
    console.log(path)
    error[path] = err.message
  })

  return error
}
