const publicRoutes = [
  {
    path: '/zid-configuration',
    component: () => import('../../pages/public/zid.vue')
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../../pages/public/404.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../../pages/public/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

export default publicRoutes
