<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
import { routesLookup } from './router'
import Vue from 'vue'

export default {
  name: 'app',
  created() {
    // const userType = localStorage.getItem('userType')
    // this.$router.addRoute('dashboard', routesLookup[userType][0])
  },
  methods: {},
  computed: {}
}
</script>

<style lang="scss">
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import 'sass/main';
@import 'sass/gridsystem';

body {
  height: 100%;
  #app {
    height: 100%;
  }
}
</style>
