<template>
  <li class="sidebar-link sidebar-link-group">
    <a
      href="#"
      target="_self"
      @click.stop.prevent="toggleMenuItem()"
      class="justify-content-between sidebar-link__router-link"
      :class="classObject"
    >
      <slot name="title"></slot>
      <i class="expand-icon fa fa-angle-down"></i>
    </a>
    <expanding>
      <ul
        class="in sidebar-submenu"
        v-show="this.expanded"
        ref="linkGroupWrapper"
      >
        <slot></slot>
      </ul>
    </expanding>
  </li>
</template>

<script>
import Expanding from 'vue-bulma-expanding/src/Expanding'
import { mapGetters } from 'vuex'

export default {
  name: 'sidebar-link-group',
  components: {
    Expanding
  },
  data() {
    return {
      expanded: this.expanded
    }
  },
  mounted() {
    let linkGroup = this.$refs.linkGroupWrapper
    if (linkGroup.querySelector('.router-link-active') !== null) {
      this.expanded = true
    }
  },
  watch: {
    $route(route) {
      this.expanded = false
    },
    currentTenant() {
      if (this.expanded) {
        this.expanded = false
        setTimeout(() => {
          this.expanded = true
        }, 500)
      }
    }
  },
  methods: {
    toggleMenuItem() {
      this.expanded = !this.expanded
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    classObject: function() {
      return {
        expanded: this.expanded
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar-link-group {
  .sidebar-link__router-link {
    .expand-icon {
      font-weight: bold;
      transition: transform 0.3s ease;
    }
    &.expanded {
      .expand-icon {
        transform: rotate(180deg);
      }
    }
  }

  .sidebar-submenu {
    list-style: none;
    padding-left: 0;
    li {
      display: block;
      padding-left: 0;
    }
    .sidebar-link__router-link {
      height: $sidebar-submenu-link-height;
      font-size: $font-size-smaller;
    }
  }
}

.expand-icon {
  color: $main-light;
}
</style>
