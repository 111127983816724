import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

const auth = Vue.axios.create({
  baseURL: process.env.VUE_APP_WEBSITE_URL.replace(
    'customer/',
    'auth/customer/'
  ),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default auth
