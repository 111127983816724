<template>
  <div class="auth-layout">
    <div class="d-lg-none logo-small-container nav">
      <router-link :to="{ path: '/' }">
        <img
          :src="require('@/assets/images/logo.svg')"
          class="login-logo"
          alt="Innocalls"
        />
      </router-link>
    </div>
    <div class="main va-row">
      <div class="flex auth-content fill-height lg6 xs12">
        <div class="justify--center va-row">
          <div class="flex md4 sm12 xs12">
            <div class="fill-height">
              <semipolar-spinner
                slot="loading"
                :animation-duration="1500"
                :size="150"
                color="#008ecf"
                v-show="isLoading"
              />
              <router-view v-show="!isLoading" />
            </div>
          </div>
          <div class="flex inno-wave lg4 md2 xs12">
            <img :src="require('@/assets/images/inno-wave.png')" alt="INNO" />
          </div>
        </div>
      </div>
      <div class="flex auth-layout__auth-wallpaper md6">
        <div class="flex-center">
          <!-- <div class="auth-layout__auth-wallpaper__cross-line" /> -->
          <router-link
            class="auth-layout__auth-wallpaper__logo"
            :to="{ path: '/' }"
          >
            <img
              :src="require('@/assets/images/logo-vert.svg')"
              class="login-logo"
              alt="Innocalls"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SemipolarSpinner from 'epic-spinners/src/components/lib/SemipolarSpinner'
export default {
  name: 'AuthLayout',
  computed: {
    ...mapGetters(['isLoading', 'authError'])
  },
  components: {
    SemipolarSpinner
  },
  watch: {
    authError: function(val) {
      this.toast(val, {
        type: 'error'
      })
    }
  }
}
</script>

<style lang="scss">
.auth-layout__auth-wallpaper__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 30%;
  }
}

.logo-small-container {
  background: $auth-layout_bg;
  a {
    height: 100%;
    widows: auto;
    text-align: center;
  }
  img {
    height: 100%;
    max-height: 100%;
    width: auto;
    max-width: 80%;
  }
}
.auth-layout {
  height: 100vh;
  margin: 0;

  &__auth-wallpaper {
    position: relative;
    background: $auth-layout_bg;
    overflow: hidden;
    @include va-flex-center();
    &__logo {
      z-index: 2;
      position: absolute;
      height: $auth-wallpaper-ivuestic-h;
      width: 100%;
    }
    .vuestic-icon-vuestic {
      height: $auth-wallpaper-ivuestic-h;
      width: 100%;
    }

    &__cross-line {
      z-index: 1;
      position: absolute;
      background-color: $auth-wallpaper-oblique-line;
      left: calc(50% - 27% / 2);
      transform: rotate(15deg);
      width: 27%;
      height: 115%;
    }
  }

  // .nav {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: $top-mobile-nav-height;
  //   background-color: $top-nav-bg;
  //   .vuestic-icon-vuestic {
  //     height: $auth-mobile-nav-ivuestic-h;
  //     width: 100%;
  //   }
  // }
  .main {
    margin: 0;
    height: 100vh;
    .auth-content {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      background-image: url('../../assets/images/login-bg.png');
      background-position: bottom left;
      background-repeat: no-repeat;
    }
  }

  @include media-breakpoint-down(md) {
    .main {
      height: $auth-mobile-main-h;
      .auth-content {
        align-items: flex-start;
        padding-top: $auth-content-padding-t;
      }
      .auth-wallpaper {
        display: none;
      }
    }
  }

  @media (min-width: 1366px) and (max-width: 1700px) {
    .inno-wave {
      img {
        max-width: 80%;
        margin-left: 25px;
      }
    }
  }

  @media (max-width: 1365px) {
    .inno-wave {
      display: none;
    }
  }
}
</style>
