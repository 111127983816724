import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// routes
import publicRoutes from './routes/public.routes'
import agentRoutes from './routes/agent.routes'
import userRoutes from './routes/user.routes'
import authRoutes from './routes/auth.routes'

Vue.use(Router)

export const routesLookup = {
  public: publicRoutes,
  auth: authRoutes,
  agent: agentRoutes,
  user: userRoutes
}

const userType = localStorage.getItem('userType') || 'user'
const roleRoutes = !!routesLookup[userType] ? routesLookup[userType] : []
const router = new Router({
  mode: 'history',
  routes: [...roleRoutes, ...authRoutes, ...publicRoutes]
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  const requiresAuth = to.matched.some(route => !!route.meta.requiresAuth)
  const authenticated = !!token

  if (requiresAuth && !authenticated) {
    // redirect to login
    next({
      name: 'login'
    })
    return
  }

  if (!to.matched.length) {
    next({
      name: '404'
    })
    return
  }

  const isPublicRoute = publicRoutes.some(({ path }) =>
    to.matched.some(({ path: matchedPath }) => matchedPath === path)
  )

  if (!requiresAuth && authenticated && !isPublicRoute) {
    // redirect to dashboard
    next({
      name: 'dashboard'
    })
    return
  }

  next()
})

router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

export default router
