import { api } from '../axios'

const userType = localStorage.getItem('userType')

const getAllContacts = async (page = 1, limit = 10) => {
  const res = await api.get(`contact/${userType}?page=${page}&limit=${limit}`)
  return res.data
}
const createContact = async contact => {
  const res = await api.post(`contact/${userType}`, contact)
  return res.data
}
const editContact = async (contactId, contact) => {
  const res = await api.put(`contact/${userType}/${contactId}`, contact)
  return res.data
}
const deleteContact = async contactId => {
  const res = await api.delete(`contact/${userType}/${contactId}`)
  return res.data
}

const ContactService = {
  getAllContacts,
  createContact,
  editContact,
  deleteContact
}

export default ContactService
