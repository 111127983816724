<template>
  <div class="detail-row-container">
    <div class="detail-box" v-if="!loading.status">
      <img :src="childData.statusImg" alt="Current Status" />
      <label class="text-primary label">Current status</label>
      <label> {{ childData.status }} </label>
    </div>
    <!-- <div class="detail-box">
      <img
        :src="require('@/assets/images/icon-logged_in_since.svg')"
        alt="Logged in since"
      />
      <label class="text-primary label">Logged in since</label>
      <label>{{ loggedInSince }}</label>
    </div> -->
    <div class="detail-box" v-if="!loading.dataDay">
      <img
        :src="require('@/assets/images/icon-answered_calls_day.svg')"
        alt="Calls answered today"
      />
      <label class="text-primary label">Calls answered today</label>
      <label>{{ childData.callAnsweredDay }}</label>
    </div>
    <div class="detail-box" v-if="!loading.dataMonth">
      <img
        :src="require('@/assets/images/icon-answered_calls_month.svg')"
        alt="Total answered calls/Month"
      />
      <label class="text-primary label">Total answered calls /Month</label>
      <label>{{ childData.callAnsweredMonth }}</label>
    </div>
    <div class="detail-box" v-if="!loading.dataDay">
      <img
        :src="require('@/assets/images/icon-call_duration_day.svg')"
        alt="On-call duration/Day"
      />
      <label class="text-primary label"> On-call duration /Day </label>
      <label>{{ childData.callDurationDay }} </label>
    </div>
    <half-circle-spinner
      slot="loading"
      :animation-duration="1500"
      :size="50"
      color="#74b9ff"
      v-if="loading.dataDay || loading.dataMonth || loading.status"
      class="my-5 mx-auto"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      loading: {
        dataDay: true,
        dataMonth: true,
        status: true
      },
      interval: undefined,
      childData: {
        statusImg: require('@/assets/images/icon-current_status_online.svg'),
        status: 'online',
        callAnsweredDay: 0,
        callAnsweredMonth: 0,
        callDurationDay: '0Hrs 0Mins 0Sec'
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchDetailRowDataDay',
      'fetchDetailRowDataMonth',
      'fetchDetailRowStatus'
    ]),
    setChildData() {
      this.interval = setInterval(() => {
        let tempDataDay = this.detailRowsDataDay[this.rowData.id]
        let tempDataMonth = this.detailRowsDataMonth[this.rowData.id]
        let tempStatus = this.detailRowsStatus[this.rowData.id]

        if (tempDataDay && Object.keys(tempDataDay).length > 1) {
          let hrs = tempDataDay.callDurationDay.hours
          let mins = tempDataDay.callDurationDay.minutes
          let secs = tempDataDay.callDurationDay.seconds
          this.childData.callDurationDay = `${hrs}Hrs ${mins}Mis ${secs}Sec`
          this.childData.callAnsweredDay = tempDataDay.callAnsweredDay
          this.loading.dataDay = false
        }
        if (tempDataMonth && Object.keys(tempDataMonth).length > 0) {
          this.childData.callAnsweredMonth = tempDataMonth.callAnsweredMonth
          this.loading.dataMonth = false
        }
        if (tempStatus && Object.keys(tempStatus).length > 0) {
          this.childData.status = tempStatus.currentStatus
          this.childData.statusImg =
            this.childData.status == 'online'
              ? require('@/assets/images/icon-current_status_online.svg')
              : require('@/assets/images/icon-current_status_offline.svg')
          this.loading.status = false
        }
        if (
          !this.loading.dataDay &&
          !this.loading.dataMonth &&
          !this.loading.status
        ) {
          clearInterval(this.interval)
        }
      }, 50)
    }
  },
  computed: {
    ...mapGetters([
      'detailRowsDataDay',
      'detailRowsDataMonth',
      'detailRowsStatus'
    ])
  },
  created() {
    this.fetchDetailRowDataDay(this.rowData.id)
    this.fetchDetailRowDataMonth(this.rowData.id)
    this.fetchDetailRowStatus(this.rowData.id)
    this.setChildData()
  }
}
</script>
<style lang="scss">
.detail-row-container {
  display: flex;
  .detail-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 15px 20px;
    img {
      max-height: 65px;
      width: auto;
    }
    .label {
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}
</style>
