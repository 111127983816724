import http from '../../axios'

export default {
  state: {
    customers: [],
    isLoading: false,
    customerFields: [],
    customerTypes: [],
    customersError: null
  },
  getters: {
    getCustomers(state) {
      return state.customers
    },
    customersAreLoading(state) {
      return state.isLoading
    },
    customersError(state) {
      return state.customersError
    },
    customerFields(state) {
      return state.customerFields
    },
    customerTypes(state) {
      return state.customerTypes
    }
  },
  mutations: {
    allCustomers(state) {
      state.isLoading = true
      state.customersError = null
    },
    allCustomersAvailable(state, payload) {
      state.isLoading = false
      state.customers = payload
    },
    allCustomersNotAvailable(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },

    allCustomerFields(state) {
      state.isLoading = true
      state.customersError = null
    },
    allCustomerFieldsAvailable(state, payload) {
      state.isLoading = false
      state.customerFields = payload
    },
    allCustomerFieldsNotAvailable(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },

    allCustomerTypes(state) {
      state.isLoading = true
      state.customersError = null
    },
    allCustomerTypesAvailable(state, payload) {
      state.isLoading = false
      state.customerTypes = payload
    },
    allCustomerTypesNotAvailable(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },

    creatingCustomerField(state) {
      state.isLoading = true
      state.customersError = null
    },
    creatingCustomerFieldSucceed(state) {
      state.isLoading = false
    },
    creatingCustomerFieldFaild(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },
    creatingCustomerType(state) {
      state.isLoading = true
      state.customersError = null
    },
    creatingCustomerTypeSucceed(state) {
      state.isLoading = false
    },
    creatingCustomerTypeFaild(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },
    creatingCustomer(state) {
      state.isLoading = true
      state.customersError = null
    },
    customerCreatingSucceed(state) {
      state.isLoading = false
    },
    customerCreatingFaild(state, payload) {
      state.isLoading = false
      state.customersError = payload
    },
    customerError(state, payload) {
      state.isLoading = false
      state.customersError = payload
    }
  },
  actions: {
    customersFields(context) {
      context.commit('allCustomerFields')
      return new Promise((resolve, reject) => {
        http
          .get('api/v1/customers/field/customer/all')
          .then(response => {
            if (!response.data.success) {
              context.commit('allCustomerFieldsNotAvailable', response.error)
              reject(response.error)
            }
            context.commit('allCustomerFieldsAvailable', response.data.data)
            resolve()
          })
          .catch(error => {
            context.commit('allCustomerFieldsNotAvailable', error.response)
            reject(error)
          })
      })
    },
    customersTypes(context) {
      context.commit('allCustomerTypes')
      return new Promise((resolve, reject) => {
        http
          .get('api/v1/customers/customer/type/all')
          .then(response => {
            context.commit('allCustomerTypesAvailable', response.data.data)
            resolve()
          })
          .catch(error => {
            context.commit('allCustomerTypesNotAvailable', error.response)
            reject(error)
          })
      })
    },
    createCustomer(context, payload) {
      context.commit('creatingCustomer')
      return new Promise((resolve, reject) => {
        http
          .post('api/v1/customers/customer/create', {
            ...payload,
            customer_type_id: payload.customer_type_id
          })
          .then(response => {
            if (response.data.success) {
              context.commit('customerCreatingSucceed')
              resolve(response.data.data.message)
            } else {
              context.commit(
                'customerCreatingFaild',
                response.data.error.message
              )
              reject(response.data.error.message)
            }
          })
          .catch(error => {
            context.commit('customerCreatingFaild', error.response)
            reject(error)
          })
      })
    },
    async allCustomers(context) {
      context.commit('setLoading', true)
      context.commit('setError', null)
      try {
        const request = await http.get(
          'api/v1/customers/customer/sub/customers/children'
        )
        if (request.status == 200) {
          context.commit('setLoading', false)
          return request.data
        } else {
          context.commit('setLoading', false)
          context.commit('setError', request.statusText)
        }
      } catch (error) {
        context.commit('setLoading', false)
        context.commit('setError', error)
      }
    },
    async customerFields(context) {
      context.commit('setError', null)
      try {
        const request = await http.get(
          'api/v1/customers/customer/sub/customers/field/header'
        )
        if (request.data.success) {
          return request.data.data
        } else {
          context.commit('setError', request.data.message)
        }
      } catch (error) {
        context.commit('setError', error)
      }
    },
    createCustomerType(context, payload) {
      context.commit('creatingCustomerType')
      return new Promise((resolve, reject) => {
        http
          .post('api/v1/customer/customer/type/create', {
            title: payload.customerType,
            description: payload.customerTypeDesc,
            can_add_children: payload.canCreate
          })
          .then(res => {
            if (response.data.success) {
              context.commit('creatingCustomerTypeSucceed')
              resolve(response.data.data.message)
            } else {
              context.commit('creatingCustomerTypeFaild', res.error)
              reject(response.data.error.message)
            }
          })
          .catch(error => {
            context.commit('creatingCustomerTypeFaild', error.response)
            reject(error)
          })
      })
    },
    createCustomerField(context, payload) {
      context.commit('creatingCustomerField')
      return new Promise((resolve, reject) => {
        http
          .post('api/v1/customer/field/create', {
            field_schema: JSON.stringify(payload)
          })
          .then(() => {
            if (response.data.success) {
              context.commit('creatingCustomerFieldSucceed')
              resolve(response.data.data.message)
            } else {
              context.commit('creatingCustomerFieldFaild', error.response)
              reject(response.data.error.message)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
