<template>
  <div class="position-relative d-flex flex-column call-pad">
    <div class="flex-fill py-5 recipient">
      <p class="mb-3">{{ recipient.replace('*199', '') }}</p>
      <!-- spying status -->
      <p class="m-0 text-primary" v-if="isSpying">
        {{ $t(`webrtc.callpad.spy-status.${spyingStatus}`) }}...
      </p>
      <p class="m-0" v-if="spyingStatus !== 'spy'">
        <small>{{ $t(`webrtc.callpad.spy-info`) }}</small>
      </p>

      <!-- status -->
      <p class="text-primary" v-if="status !== 'connected'">{{ status }}...</p>
      <div class="timer" v-else>
        <h2 class="text-primary">{{ time }}</h2>
      </div>
    </div>
    <div class="flex-fill py-5 actions">
      <div class="align-items-center no-gutters row">
        <div class="p-1 col-4">
          <button
            class="py-4 w-100 h-100 digit"
            :disabled="status !== 'connected'"
            @click="onMute"
          >
            <img
              v-if="!isMuted"
              class="mb-2"
              src="../../assets/icons/mic-off.svg"
              alt="mute"
            />
            <img
              v-if="isMuted"
              class="mb-2"
              src="../../assets/icons/mic-on.svg"
              alt="unmute"
            />
            <p v-if="!isMuted" class="m-0 text-muted">
              {{ $t('webrtc.callpad.mute') }}
            </p>
            <p v-if="isMuted" class="m-0 text-muted">
              {{ $t('webrtc.callpad.unmute') }}
            </p>
          </button>
        </div>
        <div class="p-1 col-4">
          <button
            class="py-4 w-100 h-100 digit"
            :disabled="status !== 'connected'"
            @click="onHold"
          >
            <img
              v-if="!isOnHold"
              class="mb-2"
              src="../../assets/icons/pause.svg"
              alt="hold"
            />
            <img
              v-if="isOnHold"
              class="mb-2"
              src="../../assets/icons/play.svg"
              alt="hold"
            />
            <p v-if="!isOnHold" class="m-0 text-muted">
              {{ $t('webrtc.callpad.hold') }}
            </p>
            <p v-if="isOnHold" class="m-0 text-muted">
              {{ $t('webrtc.callpad.unhold') }}
            </p>
          </button>
        </div>
        <div class="p-1 col-4">
          <button class="py-4 w-100 h-100 digit" @click="onContacts">
            <img
              class="mb-2"
              src="../../assets/icons/contacts.svg"
              alt="contacts"
            />
            <p class="m-0 text-muted">{{ $t('webrtc.callpad.contacts') }}</p>
          </button>
        </div>
        <div class="p-1 col-4">
          <button
            class="py-4 w-100 h-100 digit"
            :disabled="status !== 'connected'"
            @click="onKeypad"
          >
            <img
              class="mb-2"
              src="../../assets/icons/dialpad-black.svg"
              alt="keypad"
            />
            <p class="m-0 text-muted">{{ $t('webrtc.callpad.keypad') }}</p>
          </button>
        </div>
        <div class="p-1 col-4">
          <button
            v-if="isSpying"
            class="py-4 w-100 h-100 digit"
            :class="{ active: spyingStatus === 'whisper' }"
            :disabled="status !== 'connected'"
            @click="onWhisper"
          >
            <img
              class="mb-2"
              src="../../assets/icons/whisper.png"
              alt="keypad"
            />
            <p class="m-0 text-muted">
              {{ $t('webrtc.callpad.whisper') }}
            </p>
          </button>
        </div>
        <div class="p-1 col-4">
          <button
            v-if="isSpying"
            class="py-4 w-100 h-100 digit"
            :class="{ active: spyingStatus === 'barge' }"
            :disabled="status !== 'connected'"
            @click="onBarge"
          >
            <img class="mb-2" src="../../assets/icons/join.png" alt="keypad" />
            <p class="m-0 text-muted">
              {{ $t('webrtc.callpad.join') }}
            </p>
          </button>
        </div>

        <!--  -->
        <div class="p-1 col-4"></div>
        <div class="p-1 col-4">
          <button class="p-3 rounded-circle btn btn-danger" @click="onHangup()">
            <img src="../../assets/icons/hangup.svg" alt="hangup" />
          </button>
        </div>
        <div class="p-1 col-4">
          <button
            v-if="isKeypadOpen"
            class="py-4 w-100 h-100 digit"
            @click="onKeypad"
          >
            <img
              class="mb-2"
              src="../../assets/icons/dialpad-black.svg"
              alt="keypad"
            />
          </button>
        </div>
      </div>
      <div class="keypad-container" v-if="isKeypadOpen">
        <keypad @digitPress="onDigitPress"></keypad>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Keypad from './Keypad.vue'

export default {
  props: {
    recipient: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: () => 'connecting'
    },
    phone: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timer: 0,
      time: '00:00:00',
      isKeypadOpen: false,
      spyingStatus: 'spy'
    }
  },
  mounted() {
    if (this.status === 'connected') {
      this.resetTimer()
    }
  },
  watch: {
    status(val) {
      if (val === 'connected') {
        this.resetTimer()
      }
    }
  },
  computed: {
    isMuted() {
      return this.session && this.session.isMuted().audio
    },
    isOnHold() {
      return this.session && this.session.isOnHold().local
    },
    isSpying() {
      return this.recipient.includes('*199')
    }
  },
  methods: {
    onHangup() {
      this.$emit('hangup')
    },
    resetTimer() {
      this.timer = 0
      this.time = '00:00:00'
      setInterval(() => {
        this.timer += 1
        this.time = moment.utc(this.timer * 1000).format('HH:mm:ss')
      }, 1000)
    },
    onMute() {
      if (this.session.isMuted().audio) {
        this.session.unmute()
      } else {
        this.session.mute()
      }
    },
    onHold() {
      if (this.session.isOnHold().local) {
        this.session.unhold()
      } else {
        this.session.hold()
      }
    },
    onForward() {},
    onContacts() {},
    onKeypad() {
      this.isKeypadOpen = !this.isKeypadOpen
    },
    onSpeaker() {},
    onDigitPress(digit) {
      this.session.sendDTMF(digit.value)
    },
    onWhisper() {
      if (this.spyingStatus === 'whisper') {
        this.spyingStatus = 'spy'
        this.session.sendDTMF('4')
        return
      } else {
        this.session.sendDTMF('5')
        this.spyingStatus = 'whisper'
        return
      }
    },
    onBarge() {
      if (this.spyingStatus === 'barge') {
        this.spyingStatus = 'spy'
        this.session.sendDTMF('4')
        return
      } else {
        this.session.sendDTMF('6')
        this.spyingStatus = 'barge'
        return
      }
    }
  },
  components: { Keypad }
}
</script>

<style lang="scss" scoped>
.call-pad {
  min-height: inherit;

  button.digit {
    border: none;
    background: #fff;
    border-radius: 12px;
    outline: none;

    &:hover,
    &.active {
      background: #ededed;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  .keypad-container {
    background: #fff;
    position: absolute;
    top: 120px;
  }
}
</style>
