<template>
  <div class="contacts-pad d-flex flex-column" @scroll="onContactsScroll">
    <half-circle-spinner
      v-if="isContactsLoading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <div class="m-auto text-danger" v-if="!!contactsError">
      <p>{{ contactsError }}</p>
    </div>
    <div
      class="contacts d-flex flex-column align-items-start p-1"
      v-if="!isContactsLoading && !contactsError"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <i
            class="fa p-3 back-btn"
            :class="dir === 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"
            @click="$emit('showDialpad')"
          ></i>
          <h4 class="m-0 py-2">{{ $t('webrtc.contacts.title') }}</h4>
        </div>
        <button
          class="btn btn-primary btn-sm shadow-none mx-2 px-3 py-2"
          @click="$emit('createContact')"
        >
          + {{ $t('webrtc.contacts.add') }}
        </button>
      </div>
      <div
        class="contact p-2 w-100"
        v-for="contact in contacts"
        :class="!contact.phone ? 'disabled' : ''"
        :title="!contact.phone ? $t('webrtc.contacts.errors.no-phone') : ''"
        :key="contact.id"
        @click="$emit('showContact', contact)"
      >
        <div class="d-flex align-items-center w-100">
          <b-avatar
            variant="success"
            :text="contactAvatarText(contact)"
          ></b-avatar>
          <div
            class="flex-fill d-flex justify-content-between align-items-center"
          >
            <p class="name mx-3 mb-0" :title="contact.name || contact.phone">
              <b>{{ contact.name || contact.phone }}</b>
            </p>

            <div class="actions d-flex align-items-center">
              <button
                class="btn btn-primary btn-sm shadow-none mx-2 px-3 py-2"
                @click="onCall(contact)"
                :disabled="!contact.phone"
              >
                <i class="fa fa-phone" aria-hidden="true"></i>
              </button>
              <Dropdown align="right" class="">
                <template #trigger>
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </template>
                <template #menu>
                  <DropdownItem @click="$emit('editContact', contact)">
                    <span class="d-flex align-items-center">
                      <span class="mx-2">{{ $t('webrtc.contacts.edit') }}</span>
                    </span>
                  </DropdownItem>
                  <DropdownItem @click="$emit('deleteContact', contact)">
                    <span class="text-danger mx-2">{{
                      $t('webrtc.contacts.delete')
                    }}</span>
                  </DropdownItem>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <half-circle-spinner
      v-if="isLoadingMore"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
  </div>
</template>

<script>
import ContactService from '../../services/contact.service.js'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import DropdownItem from '../DropdownItem.vue'
import Dropdown from '../Dropdown.vue'

export default {
  components: {
    HalfCircleSpinner,
    DropdownItem,
    Dropdown
  },
  data() {
    return {
      contacts: [],
      isContactsLoading: false,
      isLoadingMore: false,
      contactsError: '',
      page: 1,
      hasNext: false
    }
  },
  mounted() {
    this.getContacts()
  },
  methods: {
    async getContacts() {
      try {
        this.isContactsLoading = true
        const { contacts, hasNext } = await ContactService.getAllContacts()
        this.contacts = contacts
        this.hasNext = hasNext
      } catch (error) {
        if (!!error.response) this.contactsError = error.response.data.message
        else this.contactsError = error.message
      } finally {
        this.isContactsLoading = false
      }
    },
    contactAvatarText(contact) {
      if (!!contact.name) {
        const [firstPart, secondPart] = contact.name.split(' ')

        const firstLetter = firstPart ? firstPart.slice(0, 1) : ''
        const secondLetter = secondPart ? secondPart.slice(0, 1) : ''
        return firstLetter + secondLetter
      }
      return ''
    },
    async onContactsScroll(event) {
      const element = event.target
      const offset = 50
      const totalHeight = element.scrollHeight - element.offsetHeight - offset

      const isAtBottom = element.scrollTop >= totalHeight
      console.log(isAtBottom)

      if (isAtBottom && !this.isLoadingMore && this.hasNext) {
        try {
          this.isLoadingMore = true
          this.page += 1
          const { contacts, hasNext } = await ContactService.getAllContacts(
            this.page
          )
          this.contacts = [...this.contacts, ...contacts]
          this.hasNext = hasNext
        } catch (error) {
          console.log(error)
        } finally {
          this.isLoadingMore = false
        }
      }
    },
    onCall(contact) {
      if (!contact.phone) {
        this.toast(this.$t('webrtc.contacts.errors.no-phone'), {
          type: 'error'
        })
      } else {
        $emit('callContact', contact)
      }
    }
  },
  computed: {
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-pad {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .back-btn {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }
  }

  .contact {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }
    &.disabled {
      opacity: 0.5;
      background: #ededed;
      cursor: not-allowed;
    }

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 65px;
      overflow: hidden;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
