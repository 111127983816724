import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import momentTimezone from 'moment-timezone'
Vue.use(VueAxios, axios)

const timezone = momentTimezone.tz.guess()

const http = Vue.axios.create({
  baseURL: process.env.VUE_APP_WEBSITE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    timezone
  }
})
http.interceptors.request.use(
  function(config) {
    const token = `${localStorage.getItem('token')}`
    const organizationId = `${localStorage.getItem('organizationId')}`
    const locale = localStorage.getItem('locale')
    const provider = localStorage.getItem('provider')

    if (token) config.headers.Authorization = `Bearer ${token}`
    if (organizationId) config.headers.organization = organizationId
    if (provider) config.headers.provider = provider

    if (config.url.includes(process.env.VUE_APP_NEW_API))
      config.headers['ngrok-skip-browser-warning'] = true

    config.headers['Accept-Language'] = locale || 'en'
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login
      Vue.toasted.error("you're not authorized please login...")
      setTimeout(() => {
        window.location.href = '/auth/login'
        localStorage.setItem('token', '')
      }, 1000)
    }
    return Promise.reject(error)
  }
)

// new api
const api = Vue.axios.create({
  baseURL: process.env.VUE_APP_NEW_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'ngrok-skip-browser-warning': true,
    timezone
  }
})

api.interceptors.request.use(
  function(config) {
    const token = `${localStorage.getItem('token')}`
    const organizationId = `${localStorage.getItem('organizationId')}`
    const locale = localStorage.getItem('locale')
    const provider = localStorage.getItem('provider')

    if (token) config.headers.Authorization = `Bearer ${token}`
    if (organizationId) config.headers.organization = organizationId
    if (provider) config.headers.provider = provider

    config.headers['Accept-Language'] = locale || 'en'
    if (config.url.includes(process.env.VUE_APP_NEW_API))
      config.headers['ngrok-skip-browser-warning'] = true

    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response && [401].includes(error.response.status)) {
      // Redirect to login
      Vue.toasted.error("you're not authorized please login...")
      setTimeout(() => {
        window.location.href = '/auth/login'
        localStorage.setItem('token', '')
        localStorage.removeItem('provider')
      }, 1000)
    }
    return Promise.reject(error)
  }
)

export { api }
export default http
