import statsService from '../../../services/stats.service'

const state = {
  averageCallDuration: {},
  averageCallDurationLoading: false,
  averageCallDurationErrorMessage: '',
  averageCallDurationError: null
}

const getters = {
  averageCallDuration(state) {
    return state.averageCallDuration
  },
  averageCallDurationLoading(state) {
    return state.averageCallDurationLoading
  },
  averageCallDurationErrorMessage(state) {
    return state.averageCallDurationErrorMessage
  },
  averageCallDurationError(state) {
    return state.averageCallDurationError
  }
}

const mutations = {
  setAverageCallDuration(state, payload) {
    state.averageCallDuration = payload
  },
  setAverageCallDurationLoading(state, payload) {
    state.averageCallDurationLoading = payload
  },
  setAverageCallDurationErrorMessage(state, payload) {
    state.averageCallDurationErrorMessage = payload
  },
  setAverageCallDurationError(state, payload) {
    state.averageCallDurationError = payload
  }
}

const actions = {
  async fetchAverageCallDuration({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setAverageCallDurationLoading', true)
      const averageCallDuration = await statsService.getAverageCallDuration()
      commit('setAverageCallDurationErrorMessage', '')
      commit('setAverageCallDuration', averageCallDuration)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setAverageCallDurationErrorMessage', errorMessage)
      commit('setAverageCallDurationError', errorResponse)
    } finally {
      if (!silent) commit('setAverageCallDurationLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
