import statsService from '../../../services/stats.service'

const state = {
  agentStats: {},
  agentStatsLoading: false,
  agentStatsErrorMessage: '',
  agentStatsError: null
}

const getters = {
  agentStats(state) {
    return state.agentStats
  },
  agentStatsLoading(state) {
    return state.agentStatsLoading
  },
  agentStatsErrorMessage(state) {
    return state.agentStatsErrorMessage
  },
  agentStatsError(state) {
    return state.agentStatsError
  }
}

const mutations = {
  setAgentStats(state, payload) {
    state.agentStats = payload
  },
  setAgentStatsLoading(state, payload) {
    state.agentStatsLoading = payload
  },
  setAgentStatsErrorMessage(state, payload) {
    state.agentStatsErrorMessage = payload
  },
  setAgentStatsError(state, payload) {
    state.agentStatsError = payload
  }
}

const actions = {
  async fetchAgentStats({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setAgentStatsLoading', true)
      const agentStats = await statsService.getAgentStats()
      commit('setAgentStatsErrorMessage', '')
      commit('setAgentStats', agentStats)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setAgentStatsErrorMessage', errorMessage)
      commit('setAgentStatsError', errorResponse)
    } finally {
      if (!silent) commit('setAgentStatsLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
