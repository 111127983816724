<template>
  <div
    class="crm-contact-card rounded-lg card p-4 w-100 d-flex align-items-start mb-3"
  >
    <p>
      {{ $t('webrtc.crm-contacts.crm-contact.name') }}: <b>{{ name }}</b>
    </p>
    <p>
      {{ $t('webrtc.crm-contacts.crm-contact.email') }}: <b>{{ email }}</b>
    </p>
    <p>
      {{ $t('webrtc.crm-contacts.crm-contact.phone') }}: <b>{{ phone }}</b>
    </p>
    <p>
      {{ $t('webrtc.crm-contacts.crm-contact.details') }}: <b>{{ details }}</b>
    </p>
    <p>
      {{ $t('webrtc.crm-contacts.crm-contact.notes') }}: <b>{{ notes }}</b>
    </p>
    <a :href="ticketsUrl" target="_blank">
      <button class="btn btn-primary btn-sm">
        {{ $t('webrtc.crm-contacts.crm-contact.tickets') }}
      </button>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    email: {
      type: String
    },
    phone: {
      type: String
    },
    details: {
      type: String
    },
    notes: {
      type: String
    },
    ticketsUrl: {
      type: String
    },
    time_zone: {
      type: String
    }
  }
}
</script>
