import AgentLayout from '../../layouts/AgentLayout.vue'
const EmptyParentComponent = {
  template: '<router-view></router-view>'
}

const agentRoutes = [
  {
    path: '/',
    component: AgentLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(`../../pages/agent/Dashboard.vue`),
        default: true
      },
      {
        path: 'call-reporting',
        name: 'call-reporting',
        component: () => import(`../../pages/agent/CallReporting.vue`)
      },
      {
        name: 'auto-dialer',
        path: 'auto-dialer',
        component: EmptyParentComponent,
        children: [
          {
            name: 'auto-dialer',
            path: '',
            component: () => import('../../pages/agent/auto-dialer/list.vue')
          },
          {
            name: 'auto-dialer-campaign-cdrs',
            path: ':id/cdrs',
            component: () =>
              import('../../pages/agent/auto-dialer/campaign-cdrs.vue')
          }
        ]
      }
    ],
    meta: { requiresAuth: true, userType: 'agent' }
  }
]

export default agentRoutes
