const listen = callback => {
  return navigator.serviceWorker.addEventListener('message', callback)
}

const broadcast = message => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message)
  }
}

export default {
  listen,
  broadcast
}
