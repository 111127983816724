import { api } from '../axios'

const getAllTags = async () => {
  const res = await api.get(`call-tag`)
  return res.data
}
const getAgentTagsOptions = () => {
  return api.get(`call-tag/agent/all`)
}
const getAgentAvailableTags = async () => {
  const res = await api.get(`call-tag/agent/available`)
  return res.data
}
const getOneTag = async tagId => {
  const res = await api.get(`call-tag/${tagId}/profile`)
  return res.data
}
const deleteTag = async tagId => {
  const res = await api.delete(`call-tag/${tagId}`)
  return res.data
}
const restoreTag = async tagId => {
  const res = await api.patch(`call-tag/${tagId}/restore`)
  return res.data
}
const createTag = async tag => {
  const res = await api.post(`call-tag`, tag)
  return res.data
}
const updateTag = async (tagId, tag) => {
  const res = await api.put(`call-tag/${tagId}`, tag)
  return res.data
}

const tagService = {
  getAllTags,
  getAgentTagsOptions,
  getAgentAvailableTags,
  getOneTag,
  deleteTag,
  restoreTag,
  createTag,
  updateTag
}

export default tagService
