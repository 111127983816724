import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'
import user from './modules/user'
import customer from './modules/customer'
import crm from './modules/crm'
import extension from './modules/extension'
import callDistribution from './modules/stats/callDistribution'
import totalAnsweredCalls from './modules/stats/totalAnsweredCalls'
import extensionsCount from './modules/stats/extensionsCount'
import onlineExtensionsCount from './modules/stats/onlineExtensionsCount'
import liveCallsCount from './modules/stats/liveCallsCount'
import averageCallDuration from './modules/stats/averageCallDuration'
import todayCallDuration from './modules/stats/todayCallDuration'
import lastHourCallDuration from './modules/stats/lastHourCallDuration'
import balance from './modules/stats/balance'
import usage from './modules/stats/usage'
import overdueInvoices from './modules/stats/overdueInvoices'
import agentStats from './modules/stats/agent-stats'
import plan from './modules/plan'
import support from './modules/support'
import reporting from './modules/reporting'
import phonebook from './modules/phonebook'
import voice from './modules/voice'
import campaign from './modules/campaign'
import bridge from './modules/bridge'
import order from './modules/order'
import survey from './modules/survey'
import dial from './modules/dial'
import billing from './modules/billing'
import orderConfirmation from './modules/orderConfirmation'
import webrtc from './modules/webrtc'

import * as getters from './getters'
Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
    user,
    customer,
    crm,
    extension,
    callDistribution,
    totalAnsweredCalls,
    extensionsCount,
    onlineExtensionsCount,
    liveCallsCount,
    averageCallDuration,
    todayCallDuration,
    lastHourCallDuration,
    balance,
    usage,
    overdueInvoices,
    agentStats,
    plan,
    support,
    reporting,
    phonebook,
    voice,
    campaign,
    bridge,
    order,
    survey,
    dial,
    billing,
    orderConfirmation,
    webrtc
  },

  state: {},
  mutations: {}
})

Vue.use(VuexI18n.plugin, store)

export default store
