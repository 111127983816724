import http from '../../axios'

export default {
  actions: {
    async allCrms(context) {
      context.commit('setLoading', true)
      context.commit('setError', null)
      try {
        const request = await http.get(
          'crm/serviceprovider/api/serviceprovider/crm/all'
        )
        const crms = request.data.data
        context.commit('setLoading', false)
        return crms
      } catch (error) {
        context.commit('setLoading', false)
        context.commit('setError', error)
      }
    },
    async authenticateCrm(context, payload) {
      context.commit('setLoading', true)
      context.commit('setError', null)
      try {
        const request = await http.post(
          'crm/serviceprovider/api/serviceprovider/crm/addcrm',
          {
            ...payload
          }
        )
        if (request.data.success) {
          context.commit('setLoading', false)
          return true
        }
      } catch (error) {
        context.commit('setLoading', false)
        context.commit('setError', error)
        return false
      }
    },
    async fetchRequiremnts(context, id) {
      context.commit('setLoading', true)
      context.commit('setError', null)
      try {
        const request = await http.get(
          `crm/serviceprovider/api/serviceprovider/crm/requirments/${id}`
        )
        if (request.data.success) {
          context.commit('setLoading', false)
          return request.data.data
        }
      } catch (error) {
        context.commit('setLoading', false)
        context.commit('setError', error)
      }
    }
  }
}
