import statsService from '../../../services/stats.service'

const state = {
  usage: {},
  usageLoading: false,
  usageErrorMessage: '',
  usageError: null
}

const getters = {
  usage(state) {
    return state.usage
  },
  usageLoading(state) {
    return state.usageLoading
  },
  usageErrorMessage(state) {
    return state.usageErrorMessage
  },
  usageError(state) {
    return state.usageError
  }
}

const mutations = {
  setUsage(state, payload) {
    state.usage = payload
  },
  setUsageLoading(state, payload) {
    state.usageLoading = payload
  },
  setUsageErrorMessage(state, payload) {
    state.usageErrorMessage = payload
  },
  setUsageError(state, payload) {
    state.usageError = payload
  }
}

const actions = {
  async fetchUsage({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setUsageLoading', true)
      const usage = await statsService.getUsage()
      commit('setUsageErrorMessage', '')
      commit('setUsage', usage)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setUsageErrorMessage', errorMessage)
      commit('setUsageError', errorResponse)
    } finally {
      if (!silent) commit('setUsageLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
