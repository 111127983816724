import http from '../../axios'

export default {
  state: {
    samplesLinks: undefined
  },
  getters: {
    samplesLinks(state) {
      return state.samplesLinks
    }
  },
  mutations: {
    SET_SAMPLES_LINKS(state, data) {
      state.samplesLinks = data
    }
  },
  actions: {
    async phonebookSamplesLinks({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get(
          'phone-book/api/v1/contact/import-sample'
        )
        commit('SET_SAMPLES_LINKS', response.data.data)
        return response.data.data
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
        } else {
          commit('setError', error.message)
        }
      }
    },
    async downloadSampleFile({ commit }, url) {
      commit('setError', null)
      try {
        const response = await http.get(url, {
          responseType: 'arraybuffer'
        })
        return response
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
        } else {
          commit('setError', error.message)
        }
      }
    },
    async addPhonebook({ commit }, payload) {
      commit('setError', null)
      try {
        const formData = new FormData()
        let params = Object.keys(payload)

        params.forEach(param => {
          if (param === 'file') {
            formData.append(param, payload[param][0])
          } else {
            formData.append(param, payload[param])
          }
        })

        const response = await http.post(
          'phone-book/api/v1/phonebook',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchPhonebook({ commit }, uuid) {
      commit('setError', null)
      try {
        const response = await http.get(
          `phone-book/api/v1/phonebook/${uuid}/show`
        )
        if (response.data.success) {
          return {
            success: true,
            data: response.data.data
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
        } else {
          commit('setError', error.message)
        }
      }
    },
    async deletePhonebook({ commit }, uuid) {
      commit('setError', null)
      try {
        const response = await http.delete(
          `phone-book/api/v1/phonebook/${uuid}/delete`
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async updatePhonebook({ commit }, payload) {
      commit('setError', null)
      try {
        // const formData = new FormData()
        // let params = Object.keys(payload)

        // params.forEach(param => {
        //   if (param === 'file') {
        //     formData.append(param, payload[param][0])
        //   } else {
        //     formData.append(param, payload[param])
        //   }
        // })

        // const response = await http.put(
        //   `phone-book/api/v1/phonebook/${payload.uuid}/update`,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   }
        // )
        const response = await http.put(
          `phone-book/api/v1/phonebook/${payload.uuid}/update`,
          {
            ...payload
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async createContact({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(`phone-book/api/v1/contact`, payload)
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async updateContact({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.put(
          `phone-book/api/v1/contact/${payload.uuid}/update`,
          payload
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async deleteContact({ commit }, uuid) {
      commit('setError', null)
      try {
        const response = await http.delete(
          `phone-book/api/v1/contact/${uuid}/delete`
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async importContacts({ commit }, payload) {
      commit('setError', null)
      const formData = new FormData()
      let params = Object.keys(payload)

      params.forEach(param => {
        if (param === 'file') {
          formData.append(param, payload[param][0])
        } else {
          formData.append(param, payload[param])
        }
      })
      try {
        const response = await http.post(
          'phone-book/api/v1/contact/import',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
