<template>
  <div>
    <button
      class="btn btn-outline-primary rounded btn-micro dropdown-toggle"
      type="button"
      slot="actuator"
    >
      {{ selected }} {{ label }}
      <i class="ion-ios-arrow-down arrow-down" />
    </button>
    <vuestic-dropdown placement="bottom">
      <template>
        <a
          class="dropdown-item"
          v-for="(option, index) in options"
          :key="index"
          @click="selectedItemsPerPage(option.value)"
          >{{ option.value }} {{ $t('tables.dataTable.perPage') }}</a
        >
      </template>
    </vuestic-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    defaultPerPage: {
      type: Number
    }
  },
  data() {
    return {
      selected: this.defaultPerPage
    }
  },
  methods: {
    selectedItemsPerPage(optionValue) {
      this.selected = optionValue
      this.$emit('items-per-page', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.dropdown-toggle,
.dropdown-menu {
  min-width: 12rem;
  max-width: 12rem;
}
</style>
