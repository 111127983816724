export default {
  state: {
    files: {}
  },
  getters: {
    files(state) {
      return state.files
    }
  },
  mutations: {
    setFile(state, payload) {
      // state.files[payload.value] = payload
    }
  },
  actions: {
    async setFile({ commit }, a) {
      console.log('a')
      commit('setFile', {})
    }
  }
}
