import http from '../../axios'
import auth from '../../auth'
import router from '../../router'

export default {
  state: {
    currentUser: null,
    currentTenant: null,
    currentJera: null,
    authError: null,
    token: localStorage.getItem('token'),
    forgetEmail: null,
    pin_code: null,
    pbxSubscription: false,
    currentUserName: null,
    organizations: [],
    agentStatus: 'idle'
  },
  getters: {
    currentUser(state) {
      return state.currentUser
    },
    currentTenant(state) {
      return state.currentTenant
    },
    currentJera(state) {
      return state.currentJera
    },
    authError(state) {
      return state.authError
    },
    token(state) {
      return state.token
    },
    forgetEmail(state) {
      return state.forgetEmail
    },
    pin_code(state) {
      return state.pin_code
    },
    pbxSubscription(state) {
      return state.pbxSubscription
    },
    currentUserName(state) {
      return state.currentUserName
    },
    organizations(state) {
      return state.organizations
    },
    agentStatus(state) {
      return state.agentStatus
    }
  },
  mutations: {
    setAccessToken(state, payload) {
      state.token = payload
      localStorage.setItem('token', payload)
    },
    setForgetEmail(state, payload) {
      state.forgetEmail = payload
    },
    setPinCode(state, payload) {
      state.pin_code = payload
    },
    setUserName(state, payload) {
      state.currentUserName = payload
    },
    setPbxSubscription(state, payload) {
      state.pbxSubscription = payload
    },
    loginFailed(state) {
      state.token = null
      state.currentUser = null
      localStorage.removeItem('token')
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('account')
    },
    authError(state, error) {
      state.authError = error
    },
    setUserInfo(state, payload) {
      state.currentUser = { ...payload }
    },
    setCurrentTenant(state, account) {
      state.currentTenant = account
    },
    setCurrentJera(state, account) {
      state.currentJera = account
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations
    },
    SOCKET_setAgentStatus(state, status) {
      state.agentStatus = status
    }
  },
  actions: {
    async userLogin({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('authError', null)
      try {
        const response = await auth.post(
          `${process.env.VUE_APP_NEW_API}auth/user/login`,
          {
            email: payload.username,
            password: payload.password
          }
        )

        const { user, organizations, accessToken } = response.data
        commit('setUserInfo', user)
        commit('setOrganizations', organizations)
        localStorage.setItem('organizationId', organizations[0].id)
        if (organizations) {
          const organizationId = localStorage.getItem('organizationId')
          const defaultOrganization =
            organizations.find(
              organization => organization.id === organizationId
            ) || organizations[0]

          commit('setCurrentTenant', defaultOrganization)
        }
        commit('setAccessToken', accessToken)
        // commit('setLoading', false)

        return response.data
      } catch (error) {
        commit('authError', error.response.data.message)
        commit('setLoading', false)
        return false
      }
    },
    async agentLogin({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('authError', null)
      try {
        const response = await auth.post(
          `${process.env.VUE_APP_NEW_API}auth/agent/login`,
          {
            email: payload.username,
            password: payload.password
          }
        )

        const { agent, accessToken } = response.data
        commit('setUserInfo', agent)
        commit('setAccessToken', accessToken)
        commit('setLoading', false)

        return response.data
      } catch (error) {
        commit('authError', error.response.data.message)
        commit('setLoading', false)
        return false
      }
    },
    async fetchCurrentUser({ commit }) {
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}user/profile`
        )

        const { user, organizations } = response.data

        commit('setUserInfo', { ...user, userType: 'user' })
        commit('setOrganizations', organizations)

        if (organizations) {
          const organizationId = localStorage.getItem('organizationId')
          const defaultOrganization =
            organizations.find(
              organization => organization.id === organizationId
            ) || organizations[0]

          commit('setCurrentTenant', defaultOrganization)
        }

        return { ...response.data, userType: 'user' }
      } catch (error) {
        commit('authError', error.response.data.message)
        commit('setError', error.response.data.message)
      }
    },
    async fetchCurrentAgent({ commit }) {
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}agent/profile`
        )

        const { organization } = response.data
        commit('setUserInfo', { ...response.data, userType: 'agent' })
        commit('setOrganizations', [organization])
        if (organization) {
          commit('setCurrentTenant', organization)
        }

        return { ...response.data, userType: 'agent' }
      } catch (error) {
        commit('authError', error.response.data.message)
        commit('setError', error.response.data.message)
      }
    },
    async forgetPassword({ commit }, payload) {
      try {
        const response = await auth.post(
          'api/v1/customer/password/reset',
          payload
        )
        if (response.status === 200) {
          return response.data.data
        } else {
          return null
        }
      } catch (error) {
        commit('setError', error.response.data.message)
      }
    },
    logout({ commit }) {
      localStorage.removeItem('token')
      localStorage.removeItem('organizationId')
      localStorage.removeItem('userType')
      router.push({ name: 'login' })
    },
    SOCKET_setAgentStatus: ({ commit }, status) => {
      console.log('status', status)
      commit('SOCKET_setAgentStatus', status)
    },
    setCurrentTenant({ commit, state: { organizations } }, account) {
      commit('setCurrentTenant', account)

      const newOrganizations = [...organizations]
      const organizationIndex = newOrganizations.findIndex(
        organization => organization.id === account.id
      )
      newOrganizations[organizationIndex] = account
      commit('setOrganizations', newOrganizations)
    }
  }
}
