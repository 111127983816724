import http from '../../axios'

export default {
  state: {
    plans: [],
    phoneNumbers: []
  },
  getters: {
    plans(state) {
      return state.plans
    },
    phoneNumbers(state) {
      return state.phoneNumbers
    }
  },
  mutations: {
    setPlans(state, plans) {
      state.plans = plans
    },
    setPhoneNumbers(state, phoneNumbers) {
      state.phoneNumbers = phoneNumbers
    }
  },
  actions: {
    async fetchPlans({ commit }) {
      commit('setLoading', true)
      commit('setError', null)
      try {
        const request = await http.get('packages/api/v1/packages/all/customer')
        if (request.data) {
          commit('setLoading', false)
          commit('setPlans', request.data.data)
          return request.data.data
        } else {
          commit('setLoading', false)
          commit('setError', request.data.error.message)
        }
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error)
      }
    },
    async fetchNumbers({ commit }) {
      commit('setError', null)
      try {
        const request = await http.get('packages/api/v1/phone/list/new')
        if (request.data) {
          commit('setPhoneNumbers', request.data.data)
          return request.data.data
        } else {
          commit('setError', request.data.error.message)
        }
      } catch (error) {
        commit('setLoading', false)
        commit('setError', error)
      }
    },
    async subscribeToPackage({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(
          'packages/api/v1/Subscription/client/subscribe/create',
          {
            plan_id: payload.id,
            phone_uuids: payload.phones
          }
        )
        return response
      } catch (error) {
        if (!error.response) {
          commit('setError', error.message)
        } else {
          commit('setError', error.response.data.message)
          return error
        }
      }
    }
  }
}
