<template>
  <div class="form-group with-icon-left">
    <div class="input-group">
      <input
        id="input-icon-left"
        class="search-input"
        name="input-icon-left"
        v-model="valueProxy"
        :placeholder="label"
      />
      <i class="glyphicon glyphicon-search search-icon"></i>
      <!-- <label class="control-label" for="input-icon-left">{{ label }}</label> -->
      <!-- <i class="bar"></i> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'filterBar',
  props: {
    label: {
      type: String
    },
    value: null
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    valueProxy: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  border: 1px solid $light-gray;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 10px 30px !important;
  height: 36px;
}
.search-icon {
  transform: rotate(90deg);
  position: absolute;
  left: 10px !important;
  top: 10px !important;
  color: lighten($gray, 10%);
}
.rtl {
  .search-icon {
    left: initial !important;
    right: 10px !important;
  }
}
.form-group {
  min-width: 18rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .form-group {
    width: 80%;
  }
}
</style>
