<template>
  <div class="contact-profile-pad d-flex flex-column">
    <div class="d-flex align-items-center">
      <i
        class="fa p-3 back-btn"
        :class="dir === 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"
        @click="$emit('showContacts')"
      ></i>
      <h5 class="m-0 py-2">{{ $t('webrtc.contacts.contact-profile') }}</h5>
    </div>
    <div class="contact-profile-form d-flex flex-column align-items-start p-1">
      <div class="form p-2 w-100">
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            class="mb-2"
            size="lg"
            variant="success"
            :text="avatarText"
          ></b-avatar>
          <p>{{ contact.name }}</p>
          <p>{{ contact.phone }}</p>
          <div class="d-flex align-items-center justify-content-center">
            <button
              class="btn btn-primary btn-sm shadow-none mx-2 px-3 py-2"
              @click="$emit('callContact', contact)"
            >
              {{ $t('webrtc.contacts.call') }}
            </button>
            <button
              class="btn btn-warning btn-sm shadow-none mx-2 px-3 py-2"
              @click="$emit('editContact', contact)"
            >
              {{ $t('webrtc.contacts.edit') }}
            </button>
            <button
              class="btn btn-danger btn-sm shadow-none mx-2 px-3 py-2"
              @click="$emit('deleteContact', contact)"
            >
              {{ $t('webrtc.contacts.delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      contactProfileLoading: false
    }
  },
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  mounted() {},
  computed: {
    avatarText() {
      if (!!this.contact.name) {
        const [firstPart, secondPart] = this.contact.name.split(' ')

        const firstLetter = firstPart ? firstPart.slice(0, 1) : ''
        const secondLetter = secondPart ? secondPart.slice(0, 1) : ''
        return firstLetter + secondLetter
      }
      return ''
    },
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.contact-profile-pad {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .back-btn {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
