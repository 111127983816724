import http from '../../axios'

export default {
  actions: {
    async createVoiceMessage({ commit }, payload) {
      commit('setError', null)
      try {
        const formData = new FormData()
        let params = Object.keys(payload)

        params.forEach(param => {
          if (param === 'sound') {
            formData.append(param, payload[param][0])
          } else {
            formData.append(param, payload[param])
          }
        })

        const response = await http.post(
          'campaign/out/api/v1/sound',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async deleteVoiceMessage({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.delete(`campaign/out/api/v1/sound`, {
          data: { id }
        })
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchVoiceMessage({ commit }, url) {
      commit('setError', null)
      try {
        const response = await http.get(url, {
          contentType: 'audio/mpeg'
        })
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
