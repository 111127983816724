import http from '../../axios'
import store from '../../store'

export default {
  actions: {
    async scheduleReport({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get('ticketing/api/v1/departments/all')
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async exportReport({ commit }, { filter }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}cdr/export?from_date=${filter.from_date}&call_statuses=${filter.call_statuses}&to_date=${filter.to_date}&userEmail=${store.getters.currentUser.email}`
        )
        return response
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async scheduleReport({ commit }, model) {
      commit('setError', null)

      try {
        const response = await http.post(
          'sip/provisioningwa/api/v1/call-log-report/schedule',
          model
        )
        if (response.data.success) {
          return {
            success: true,
            data: response.data.data
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
