<template>
  <li class="sidebar-link">
    <router-link
      v-if="!external"
      class="sidebar-link__router-link"
      :to="to"
      :target="target"
    >
      <slot name="title"></slot>
    </router-link>
    <a v-else :href="href" :target="target" class="sidebar-link__router-link">
      <slot name="title"></slot>
    </a>
  </li>
</template>

<script>
export default {
  name: 'sidebar-link',
  props: {
    to: {
      type: Object,
      required: false
    },
    target: {
      type: String,
      default: '_self'
    },
    external: {
      type: Boolean,
      default: false,
      required: false
    },
    href: {
      type: String,
      default: '#',
      required: false
    }
  },
  watch: {
    $route(route) {
      this.$nextTick(() => {
        if (this.$children.length) {
          const isActive = this.$children[0].$el.classList.contains(
            'router-link-active'
          )
          if (!isActive) {
            return
          }
          const linkGroup = this.$parent && this.$parent.$parent
          if (linkGroup.$options.name !== 'sidebar-link-group') {
            return
          }
          linkGroup.expanded = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
.sidebar-link {
  .sidebar-link__router-link {
    position: relative;
    height: $sidebar-link-height;
    padding: 0 $sidebar-link-pl;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 1368px) {
      height: 50px;
      padding: 0 18px;
    }
    &.router-link-exact-active,
    &:hover {
      color: $white;
      background-color: $sidebar-link-active-bg;
      .sidebar-menu-item-icon,
      .expand-icon {
        color: $white;
      }
    }
    &:hover {
      background-color: $darkblue;
    }
    .sidebar-menu-item-icon {
      font-size: $sidebar-menu-item-icon-size;
      color: $main-light;
      width: 35px;
      &.fa-dashboard {
        /* Temp fix */
        position: relative;
        top: -2px;
      }
    }
  }

  a {
    color: $dark-gray;
    text-decoration: none;
    font-weight: 400;
    @media (max-width: 1368px) {
      font-size: 14px;
    }
  }
}
</style>
