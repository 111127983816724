import http from '../../axios'

export default {
  state: {
    callersIDs: undefined,
    phonebooks: undefined,
    voicerecords: undefined,
    campaignTotals: undefined,
    campaignAnsweredDistribution: undefined,
    campaignsProgressChanged: false
  },
  getters: {
    callersIDs(state) {
      return state.callersIDs
    },
    phonebooks(state) {
      return state.phonebooks
    },
    voicerecords(state) {
      return state.voicerecords
    },
    campaignTotals(state) {
      return state.campaignTotals
    },
    campaignTotalsChart(state) {
      return state.campaignTotals
        ? state.campaignTotals.chart
        : state.campaignTotals
    },
    campaignAnsweredDistribution(state) {
      return state.campaignAnsweredDistribution
    },
    campaignsProgressChanged(state) {
      return state.campaignsProgressChanged
    }
  },
  mutations: {
    SET_CALLERS_IDS(state, payload) {
      state.callersIDs = payload
    },
    SET_PHONEBOOKS(state, payload) {
      state.phonebooks = payload
    },
    SET_VOICERECORDS(state, payload) {
      state.voicerecords = payload
    },
    SET_CAMPAIGN_TOTALS(state, payload) {
      state.campaignTotals = payload
    },
    SET_CAMPAIGN_ANSWERED_DISTRIBUTION(state, payload) {
      state.campaignAnsweredDistribution = payload
    },
    SET_CHECK_CAMPAIGNS_PROGRESS(state, payload) {
      state.campaignsProgressChanged = payload
    }
  },
  actions: {
    async fetchCallersIDs({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get('billing/api/v3/did/search')
        if (response.data.success) {
          commit('SET_CALLERS_IDS', response.data.data.dids)
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchPhonebooks({ commit }) {
      commit('setError', null)
      try {
        /**
         * FIXED TO 100 RECORDS TO FIX LATER, Pagination in Dropdown
         */
        const response = await http.get(
          'phone-book/api/v1/phonebook?per_page=100'
        )
        if (response.data.data) {
          commit('SET_PHONEBOOKS', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchVoicerecords({ commit }) {
      commit('setError', null)
      try {
        /**
         * FIXED TO 100 RECORDS TO FIX LATER, Pagination in Dropdown
         */
        const response = await http.get(
          'campaign/out/api/v1/sound?per_page=100'
        )
        if (response.data.data) {
          commit('SET_VOICERECORDS', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async createCampaign({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(
          `campaign/service/api/v1/campaign`,
          payload
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async deleteCampaign({ commit }, uuid) {
      commit('setError', null)
      try {
        const response = await http.delete(
          `campaign/service/api/v1/campaign/${uuid}/delete`
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async testPhonecall({ commit }, { caller, callee, voiceUUID }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `campaign/service/api/v1/test-call?caller_number=${caller}&callee_number=${callee}&sound_uuid=${voiceUUID}`
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchCampaignTotalsStatistics({ commit }, uuid) {
      commit('setError', null)
      try {
        /**
         * FIXED TO 100 RECORDS TO FIX LATER, Pagination in Dropdown
         */
        const response = await http.get(
          `campaign/service/api/v1/campaign/log/list?campaignUuid=${uuid}`
        )
        if (response.data.data) {
          commit('SET_CAMPAIGN_TOTALS', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchCampaignAnsweredDistribution({ commit }, uuid) {
      commit('setError', null)
      try {
        /**
         * FIXED TO 100 RECORDS TO FIX LATER, Pagination in Dropdown
         */
        const response = await http.get(
          `campaign/service/api/v1/campaign/log/percent?campaignUuid=${uuid}`
        )
        if (response.data.data) {
          commit('SET_CAMPAIGN_ANSWERED_DISTRIBUTION', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async checkCampaignsProgressChanged({ commit }, uuid) {
      commit('setError', null)
      try {
        /**
         * FIXED TO 100 RECORDS TO FIX LATER, Pagination in Dropdown
         */
        const response = await http.get(
          `campaign/service/api/v1/check-progress`
        )
        if (response.data.data) {
          commit('SET_CHECK_CAMPAIGNS_PROGRESS', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
