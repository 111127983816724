export default {
  hex2rgb(hex, opacity) {
    hex = (hex + '').trim()

    let rgb = null
    let match = hex.match(/^#?(([0-9a-zA-Z]{3}){1,3})$/)

    if (!match) {
      return null
    }

    rgb = {}

    hex = match[1]

    if (hex.length === 6) {
      rgb.r = parseInt(hex.substring(0, 2), 16)
      rgb.g = parseInt(hex.substring(2, 4), 16)
      rgb.b = parseInt(hex.substring(4, 6), 16)
    } else if (hex.length === 3) {
      rgb.r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16)
      rgb.g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16)
      rgb.b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16)
    }

    rgb.css = 'rgb' + (opacity ? 'a' : '') + '('
    rgb.css += rgb.r + ',' + rgb.g + ',' + rgb.b
    rgb.css += (opacity ? ',' + opacity : '') + ')'

    return rgb
  }
}

export const getApiErrorMessage = error => {
  return error.response ? error.response.data.message : error.message
}

export const onlyAcceptNumbers = evt =>
  ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()

export function removeNullEntries(obj) {
  const result = {}
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null) {
      result[key] = value
    }
  }
  return result
}

export const parseUser = user => {
  let name = user.split(',')[0]
  let channelId = user.split(',')[1]

  return { name, channelId }
}

export const toQueryString = (obj, prefix) => {
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p
      const v = obj[p]
      if (v === undefined || v === null || v === '') continue
      str.push(
        v !== null && typeof v === 'object'
          ? toQueryString(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      )
    }
  }
  return str.filter(Boolean).join('&')
}
