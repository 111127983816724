<template>
  <div class="d-flex flex-row-reverse mb-3 virtual-phone-container">
    <div
      class="d-flex flex-column bg-white virtual-phone"
      :class="{ 'side-open': channelId }"
    >
      <div
        v-if="showExtensionDetails"
        :class="extensionDetailsColor"
        class="px-4 py-2 text-white extension-details"
        @click="onReconnect()"
      >
        <span>
          {{ extension.ext }} {{ $t(`webrtc.connection-status.${status}`) }}
        </span>
      </div>

      <div class="d-flex flex-column flex-fill p-2 pads">
        <!-- pads -->
        <extensions-pad
          v-if="view === 'extensions-pad'"
          @extensionLogin="onExtensionLogin($event)"
        ></extensions-pad>
        <dialpad
          v-if="view === 'dial-pad'"
          @showContacts="view = 'contacts-pad'"
          @showCrmContacts="view = 'crm-contacts-pad'"
          @showCallLog="view = 'call-log'"
          @startCall="onStartCall($event)"
          @extensionLogout="onExtensionLogout()"
        ></dialpad>
        <contacts
          v-if="view === 'contacts-pad'"
          @showDialpad="view = 'dial-pad'"
          @callContact="onStartCall($event.phone)"
          @showCreateContactPad="view = 'create-contact-pad'"
          @showEditContactPad="view = 'edit-contact-pad'"
        >
        </contacts>
        <crm-contacts
          v-if="view === 'crm-contacts-pad'"
          @showDialpad="view = 'dial-pad'"
          @callContact="onStartCall($event.phone)"
        >
        </crm-contacts>
        <call-pad
          ref="callpad"
          v-if="view === 'call-pad'"
          :phone="phone"
          :session="session"
          :status="callStatus"
          :recipient="recipient"
          @hangup="onHangup()"
        ></call-pad>
        <incoming-call-pad
          v-if="view === 'incoming-call-pad'"
          :call="callEvent"
          @answer="onIncomingCallAnswer"
          @hangup="onIncomingCallHangup"
        ></incoming-call-pad>
        <call-log
          v-if="view === 'call-log'"
          :extension="extension.ext"
          @callContact="onStartCall($event)"
          @showDialpad="view = 'dial-pad'"
        ></call-log>
      </div>
    </div>

    <div
      class="bg-white side"
      v-if="channelId"
      :class="{ 'open-from-side': channelId }"
    >
      <CallDetails
        :channelId="channelId"
        @notesSaved="channelId = ''"
        @close="channelId = ''"
      ></CallDetails>
    </div>

    <b-modal
      v-model="isContactModalOpen"
      centered
      scrollable
      hide-header
      hide-footer
      :dir="dir"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="crm-contacts-modal">
        <div class="d-flex justify-content-between align-items-center head">
          <h1 class="mt-0 mb-2">{{ $t('webrtc.crm-contacts.title') }}</h1>
          <div class="p-2 back-btn" role="button" @click="onCloseContactModal">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          class="mx-auto"
          v-if="crmContactsLoading"
        />
        <div class="contacts" v-else-if="currentContacts.length === 0">
          <div class="d-flex align-items-center mb-2">
            <h5 class="m-2">
              {{ $t('webrtc.crm-contacts.create-new-contact') }}
            </h5>
          </div>
          <CrmCreateContactForm
            :callee="callee"
            @contactCreated="isContactModalOpen = false"
          />
        </div>
        <div class="w-100 contacts" v-else-if="currentContacts.length > 0">
          <div class="row">
            <div
              class="col-12"
              v-for="contact in currentContacts"
              :key="contact.id"
            >
              <CrmContactCard class="w-100" v-bind="contact" />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="isConfirmContactModalOpen"
      centered
      scrollable
      hide-header
      hide-footer
      :dir="dir"
    >
      <div
        class="d-flex flex-column align-items-center confirm-contact-modal-close"
      >
        <h4 class="mt-0 mb-4">
          {{ $t('webrtc.crm-contacts.confirm-modal.title') }}
        </h4>
        <div class="">
          <button
            class="mx-2 btn btn-primary"
            @click="
              isConfirmContactModalOpen = false
              isContactModalOpen = false
            "
          >
            {{ $t('webrtc.crm-contacts.confirm-modal.confirm') }}
          </button>
          <button
            class="mx-2 btn btn-secondary"
            @click="isConfirmContactModalOpen = false"
          >
            {{ $t('webrtc.crm-contacts.confirm-modal.discard') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-model="isCallEndedModalOpen"
      centered
      scrollable
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      :dir="dir"
      header-class="align-items-center justify-content-between"
    >
      <template #modal-title> {{ $t('phone.summary-modal.title') }} </template>
      <div class="d-flex flex-column confirm-contact-modal-close">
        <SummaryForm @submitCallSummary="submitCallSummary" />
      </div>
    </b-modal>
  </div>
</template>

<script>
// constants
import { extensionDetailsColors } from '../../constants/dialpad'

// components
import CallPad from './CallPad.vue'
import Dialpad from './Dialpad.vue'
import ExtensionsPad from './ExtensionsPad.vue'
import IncomingCallPad from './IncomingCallPad.vue'
import Contacts from './Contacts.vue'
import CallLog from './CallLog.vue'
import CallDetails from './CallDetails.vue'

// services
import webRTCService from '../../services/webrtc.service'
import sipService from '../../services/sip.service'
import serviceWorkerService from '../../services/serviceWorker.service'
import ExtensionService from '../../services/extension.service'
import CDRService from '../../services/cdr.service'

import { mapGetters } from 'vuex'
import { addCallToLog } from '../../utils/callLog'
import { parseUser } from '../../utils/utils'
import CrmContacts from './Crm/CrmContacts.vue'
import CrmService from '../../services/crm.service'
import CrmContactCard from './Crm/CrmContactCard.vue'
import CrmCreateContactForm from './Crm/CrmCreateContactForm.vue'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import moment from 'moment'
import SummaryForm from './SummaryForm.vue'

export default {
  components: {
    IncomingCallPad,
    ExtensionsPad,
    Dialpad,
    CallPad,
    Contacts,
    CrmContacts,
    CallLog,
    CrmContactCard,
    CrmCreateContactForm,
    HalfCircleSpinner,
    SummaryForm,
    CallDetails
  },
  data() {
    return {
      recipient: '',
      status: 'disconnected',
      callStatus: '',
      callDirection: '',
      callDateTime: '',
      callDuration: '',
      phone: null,
      session: null,
      callEvent: null,
      extension: null,
      callee: {},
      view: '',
      isContactModalOpen: false,
      isConfirmContactModalOpen: false,
      currentContacts: [],
      crmContactsLoading: false,
      isReconnecting: false,
      isCallEndedModalOpen: false,
      callId: '',
      channelId: ''
    }
  },
  computed: {
    ...mapGetters(['extensionStatus', 'currentTenant', 'currentUser']),
    extensionDetailsColor() {
      return extensionDetailsColors[this.status]
    },
    showExtensionDetails() {
      return !!this.extension && this.view !== 'extensions-pad'
    },
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  },
  watch: {
    extensionStatus(val) {
      if (val === 'disconnected') {
        this.phone.stop()
        this.status = 'disconnected'
      } else if (val === 'connected') {
        this.phone.start()
        this.status = 'connected'
      }
    },
    view(val) {
      if (val === 'call-pad') {
        window.onbeforeunload = event => {
          this.onHangup()
          return null
        }
      } else {
        window.onbeforeunload = null
      }
    }
  },
  methods: {
    async onReconnect() {
      if (this.isReconnecting || this.status === 'connecting') return

      this.isReconnecting = true
      await this.logout()
        .then(async () => {
          this.onExtensionLogin(this.extension)
        })
        .finally(() => {
          this.isReconnecting = false
        })
    },
    async logout() {
      return await new Promise((resolve, reject) => {
        if (navigator.onLine === false) {
          reject('you are probably offline check your network')
        }

        if (!this.phone || !this.phone.isRegistered()) resolve()

        this.phone.terminateSessions()
        this.phone.stop()
        this.phone.unregister()

        const onUnregistered = () => {
          resolve()
          this.phone.off('unregistered', onUnregistered)
          this.phone.removeAllListeners()
          this.phone = null
        }

        this.phone.on('unregistered', onUnregistered)
      })
    },
    onExtensionLogin(extension) {
      this.status = 'connecting'

      this.view = 'dial-pad'
      this.extension = extension
      this.phone = sipService.login(extension)

      this.phone.on('connected', e => {
        window.addEventListener('offline', () => {
          this.status = 'disconnected'
          console.log('phone', this.phone)
          this.phone.stop()
        })
        window.addEventListener('online', this.onReconnect)

        this.status = 'connected'
        this.$store.dispatch('extensionLogin')

        if (this.$socket)
          this.$socket.emit('agent-status', {
            data: {
              status: 'idle'
            }
          })
        serviceWorkerService.broadcast({
          action: 'connect',
          extension
        })
      })

      this.phone.on('disconnected', event => {
        this.status = 'disconnected'
        this.$store.dispatch('extensionLogout')

        if (this.$socket)
          this.$socket.emit('agent-status', {
            data: {
              status: 'disconnected'
            }
          })
        serviceWorkerService.broadcast({
          action: 'disconnect'
        })
      })
      this.phone.on('newRTCSession', event => {
        this.channelId = ''
        if (event.originator === 'remote') {
          // handle incoming calls
          this.handleIncomingCalls(event)
        } else if (event.originator === 'local') {
          this.callStatus = 'connecting'
        }
        this.callDateTime = moment().format('DD-MM-YYYY, h:mm:ss a')
      })
    },
    onStartCall(recipient) {
      this.view = 'call-pad'
      this.recipient = recipient
      this.callDirection = 'outgoing'

      addCallToLog(
        {
          number: recipient,
          name: recipient,
          type: 'outgoing'
        },
        this.extension.ext
      )

      const eventHandlers = {
        progress: e => {
          this.callStatus = 'ringing'
          this.view = 'call-pad'
        },
        failed: async event => {
          this.callStatus = event.message
            ? event.message.reason_phrase
            : event.cause

          setTimeout(() => {
            this.view = 'dial-pad'
            this.callStatus = ''
          }, 2000)

          // this.isCallEndedModalOpen = true

          if (!!this.currentTenant.defaultCRM) {
            await CrmService.onCallFailed({
              callDirection: 'outgoing',
              callTotalTime: 0,
              phone: recipient,
              callStatus: 'failed',
              extension: {
                number: +this.extension.ext,
                email: this.extension.email
              },
              callTime: moment().format('DD-MM-YYYY, h:mm:ss a')
            })
          }

          if (this.$socket)
            this.$socket.emit('agent-status', {
              data: {
                status: 'idle'
              }
            })
          //

          this.session = null
          this.callEvent = null
        },
        ended: async () => {
          this.callDuration = this.$refs.callpad.timer
          if (
            !!this.callId &&
            this.currentTenant &&
            this.currentTenant.enableAfterCallTags
          ) {
            this.isCallEndedModalOpen = true
          }

          this.callStatus = ''
          this.view = 'dial-pad'

          if (!!this.currentTenant.defaultCRM) {
            await CrmService.onCallEnd({
              callDirection: 'outgoing',
              callTotalTime: this.$refs.callpad.timer,
              phone: recipient,
              callStatus: 'answered',
              extension: {
                number: +this.extension.ext,
                email: this.extension.email
              },
              endTime: moment().format('DD-MM-YYYY, h:mm:ss a')
            })
          }

          if (this.$socket)
            this.$socket.emit('agent-status', {
              data: {
                status: 'idle'
              }
            })

          this.session = null
          this.callEvent = null
        },
        confirmed: async event => {
          this.callStatus = 'connected'
          this.view = 'call-pad'

          if (!!this.currentTenant.defaultCRM) {
            await CrmService.onCallStart({
              callDirection: 'outgoing',
              callTotalTime: this.$refs.callpad.timer,
              phone: recipient,
              callStatus: 'answered',
              extension: {
                number: +this.extension.ext,
                email: this.extension.email
              },
              startTime: moment().format('DD-MM-YYYY, h:mm:ss a')
            })
          }

          if (this.currentUser.userType === 'agent') {
            const callId = await this.getCallId(recipient, 'outbound')
            this.callId = callId
          }
        }
      }

      const options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false }
      }

      const session = this.phone.call(recipient, options)
      if (this.$socket)
        this.$socket.emit('agent-status', {
          data: {
            status: 'on_call'
          }
        })
      session.connection.addEventListener('addstream', e => {
        const remoteAudio = document.createElement('audio')
        remoteAudio.srcObject = e.stream
        remoteAudio.play()
      })
      session.connection.addEventListener('track', e => {
        const remoteAudio = document.createElement('audio')
        remoteAudio.srcObject = e.streams && e.streams[0] ? e.streams[0] : null
        remoteAudio.play()
      })
      this.session = session
    },
    onExtensionLogout() {
      this.phone.stop()
      this.view = 'extensions-pad'
    },
    onHangup() {
      this.view = 'dial-pad'
      this.session.terminate()
    },
    handleIncomingCalls(event) {
      // there is already a session running
      if (!!this.callEvent || !!this.session) return

      this.$emit('incomingCall')
      this.view = 'incoming-call-pad'
      this.callDirection = 'incoming'

      this.callEvent = event

      const number = event.request.from.uri.user
      const { name: displayName, channelId } =
        parseUser(event.request.from.display_name) || number

      this.recipient = number

      const audio = new Audio('/music/ringtone.mp3')
      audio.loop = true
      audio.play()

      event.session.on('accepted', async e => {
        audio.pause()
        addCallToLog(
          {
            number: number,
            name: displayName,
            type: 'incoming'
          },
          this.extension.ext
        )

        console.log('accepted', e)
        if (this.$socket)
          this.$socket.emit('agent-status', {
            data: {
              status: 'on_call'
            }
          })

        // if the current tenant has a default crm
        if (!!this.currentTenant.defaultCRM) {
          this.isContactModalOpen = true
          this.crmContactsLoading = true
          this.callee = { number, displayName }
          const res = await CrmService.getPhoneNumberDetails(number)
          this.crmContactsLoading = false
          this.currentContacts = res.contacts

          await CrmService.onCallStart({
            callDirection: 'incoming',
            callTotalTime: this.$refs.callpad.timer,
            phone: number,
            callStatus: 'answered',
            extension: {
              number: +this.extension.ext,
              email: this.extension.email
            },
            startTime: moment().format('DD-MM-YYYY, h:mm:ss a')
          })
        }
      })
      event.session.on('confirmed', async e => {
        audio.pause()
        console.log('confirmed', e)
        // if the current tenant is agent
        if (this.currentUser.userType === 'agent') {
          const callId = await this.getCallId(number, 'inbound')
          this.callId = callId
        }
        this.channelId = channelId
      })
      event.session.on('ended', async () => {
        this.callDuration = this.$refs.callpad.timer
        audio.pause()
        this.view = 'dial-pad'

        if (
          !!this.callId &&
          this.currentTenant &&
          this.currentTenant.enableAfterCallTags
        ) {
          this.isCallEndedModalOpen = true
        }

        if (this.$socket)
          this.$socket.emit('agent-status', {
            data: {
              status: 'idle'
            }
          })

        if (!!this.currentTenant.defaultCRM) {
          await CrmService.onCallEnd({
            callDirection: 'incoming',
            callTotalTime: this.$refs.callpad.timer,
            phone: number,
            callStatus: 'answered',
            extension: {
              number: +this.extension.ext,
              email: this.extension.email
            },
            endTime: moment().format('DD-MM-YYYY, h:mm:ss a')
          })
        }

        this.callEvent = null
        this.session = null
      })
      event.session.on('failed', async e => {
        audio.pause()
        this.view = 'dial-pad'
        this.callEvent = null
        this.session = null
        this.channelId = ''

        // this.isCallEndedModalOpen = true

        const causesToLogTypeLookup = {
          Canceled: 'missed',
          Rejected: 'rejected'
        }

        addCallToLog(
          {
            number: number,
            name: displayName,
            type: causesToLogTypeLookup[e.cause]
          },
          this.extension.ext
        )

        console.log('failed')
        if (this.$socket)
          this.$socket.emit('agent-status', {
            data: {
              status: 'idle'
            }
          })
      })
    },
    onIncomingCallAnswer() {
      this.session = this.callEvent.session
      this.session.answer()
      this.callStatus = 'connected'
      this.session.connection.addEventListener('addstream', e => {
        const remoteAudio = document.createElement('audio')
        remoteAudio.srcObject = e.stream
        remoteAudio.play()
      })

      this.session.connection.addEventListener('track', e => {
        const remoteAudio = document.createElement('audio')
        remoteAudio.srcObject = e.streams && e.streams[0] ? e.streams[0] : null
        remoteAudio.play()
      })

      this.view = 'call-pad'
    },
    onIncomingCallHangup() {
      this.callEvent.session.terminate()
    },
    onCloseContactModal() {
      this.isConfirmContactModalOpen = true
    },
    async getCallId(ext, direction) {
      try {
        const res = await ExtensionService.getCallId(ext, direction)
        return res.callId
      } catch (error) {
        console.log(error)
        return ''
      }
    },
    async submitCallSummary(postCallData) {
      try {
        await CDRService.submitCallSummary({
          ...postCallData,
          callId: this.callId,
          from:
            this.callDirection === 'outgoing'
              ? this.extension.ext
              : this.recipient,
          to:
            this.callDirection === 'outgoing'
              ? this.recipient
              : this.extension.ext,
          direction: this.callDirection,
          duration: this.callDuration,
          callDateTime: this.callDateTime
        })
        this.isCallEndedModalOpen = false
        this.callId = ''
        this.toast(this.$t('phone.summary-modal.form.toasts.summary-submitted'))
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    async spy(ext) {
      // if already on call
      if (this.session) {
        this.toast(this.$t('webrtc.spy.error.already-on-call'), {
          type: 'error'
        })
      }

      this.onStartCall(`*199${ext}`)
    }
  },
  async mounted() {
    // check if user have webRTC extensions permission
    const permissions = this.$gates.getPermissions()
    const userType = localStorage.getItem('userType')
    if (userType === 'agent') {
      const extension = await webRTCService.getUserExtension()
      this.extension = extension
      this.onExtensionLogin(extension)
    } else if (userType === 'user') {
      this.view = 'extensions-pad'
    }

    // listen for another tab connections
    serviceWorkerService.listen(event => {
      // console.log(event)
    })
  }
}
</script>

<style lang="scss" scoped>
.virtual-phone {
  width: 300px;
  min-height: 400px;
  position: relative;
  z-index: 10;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 0 15px -5px #00000040;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  &.side-open {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .extension-details {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
.virtual-phone-container {
  border-radius: 12px;
}

.back-btn {
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #ededed;
  }
}
div[id*='BV_modal_outer'] {
  z-index: 9999999 !important;
}

// animation to open the side panel
@keyframes open-from-side {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.side {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: 0 0 15px -5px #00000040;
  min-width: 600px;
  max-height: 534px;
  overflow-y: auto;
}

.open-from-side {
  animation: open-from-side 0.3s ease;
}
</style>

<style lang="scss">
.toasted-container {
  z-index: 99999999 !important;
}
.modal-header button.close {
  margin: 0;
  padding: 0;
}
.modal-header {
  align-items: center !important;
}
</style>
