import { api } from '../axios'

const userType = localStorage.getItem('userType')

const getCrmContacts = async (page = 1, limit = 10) => {
  const res = await api.get(
    `crm-contact/${userType}?page=${page}&limit=${limit}`
  )
  return res.data
}

const getPhoneNumberDetails = async number => {
  const res = await api.get(`crm-contact/${userType}/search?phone=${number}`)
  return res.data
}

const createContact = async payload => {
  const res = await api.post(`crm-contact/${userType}`, payload)
  return res.data
}

const onCallStart = async payload => {
  const res = await api.post(`crm-contact/${userType}/call-start`, payload)
  return res.data
}
const onCallEnd = async payload => {
  const res = await api.post(`crm-contact/${userType}/call-end`, payload)
  return res.data
}
const onCallFailed = async payload => {
  const res = await api.post(`crm-contact/${userType}/call-failed`, payload)
  return res.data
}

const CrmService = {
  getCrmContacts,
  getPhoneNumberDetails,
  createContact,
  onCallStart,
  onCallEnd,
  onCallFailed
}

export default CrmService
