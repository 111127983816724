import statsService from '../../../services/stats.service'

const state = {
  lastHourCallDuration: {},
  lastHourCallDurationLoading: false,
  lastHourCallDurationErrorMessage: '',
  lastHourCallDurationError: null
}

const getters = {
  lastHourCallDuration(state) {
    return state.lastHourCallDuration
  },
  lastHourCallDurationLoading(state) {
    return state.lastHourCallDurationLoading
  },
  lastHourCallDurationErrorMessage(state) {
    return state.lastHourCallDurationErrorMessage
  },
  lastHourCallDurationError(state) {
    return state.lastHourCallDurationError
  }
}

const mutations = {
  setLastHourCallDuration(state, payload) {
    state.lastHourCallDuration = payload
  },
  setLastHourCallDurationLoading(state, payload) {
    state.lastHourCallDurationLoading = payload
  },
  setLastHourCallDurationErrorMessage(state, payload) {
    state.lastHourCallDurationErrorMessage = payload
  },
  setLastHourCallDurationError(state, payload) {
    state.lastHourCallDurationError = payload
  }
}

const actions = {
  async fetchLastHourCallDuration({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setLastHourCallDurationLoading', true)
      const lastHourCallDuration = await statsService.getLastHourCallDuration()
      commit('setLastHourCallDurationErrorMessage', '')
      commit('setLastHourCallDuration', lastHourCallDuration)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setLastHourCallDurationErrorMessage', errorMessage)
      commit('setLastHourCallDurationError', errorResponse)
    } finally {
      if (!silent) commit('setLastHourCallDurationLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
