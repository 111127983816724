import statsService from '../../../services/stats.service'

const state = {
  overdueInvoices: {},
  overdueInvoicesLoading: false,
  overdueInvoicesErrorMessage: '',
  overdueInvoicesError: null
}

const getters = {
  overdueInvoices(state) {
    return state.overdueInvoices
  },
  overdueInvoicesLoading(state) {
    return state.overdueInvoicesLoading
  },
  overdueInvoicesErrorMessage(state) {
    return state.overdueInvoicesErrorMessage
  },
  overdueInvoicesError(state) {
    return state.overdueInvoicesError
  }
}

const mutations = {
  setOverdueInvoices(state, payload) {
    state.overdueInvoices = payload
  },
  setOverdueInvoicesLoading(state, payload) {
    state.overdueInvoicesLoading = payload
  },
  setOverdueInvoicesErrorMessage(state, payload) {
    state.overdueInvoicesErrorMessage = payload
  },
  setOverdueInvoicesError(state, payload) {
    state.overdueInvoicesError = payload
  }
}

const actions = {
  async fetchOverdueInvoices({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setOverdueInvoicesLoading', true)
      const overdueInvoices = await statsService.getOverdueInvoices()
      commit('setOverdueInvoicesErrorMessage', '')
      commit('setOverdueInvoices', overdueInvoices)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setOverdueInvoicesErrorMessage', errorMessage)
      commit('setOverdueInvoicesError', errorResponse)
    } finally {
      if (!silent) commit('setOverdueInvoicesLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
