<template>
  <div class="vuestic-layout" v-resize :class="classObject">
    <slot></slot>
    <div class="content-wrap" id="content-wrap">
      <slot name="content"></slot>
    </div>
    <div class="made-by-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Resize from '../../../directives/ResizeHandler'
export default {
  name: 'vuestic-layout',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    resize: Resize
  },
  computed: {
    classObject: function() {
      return {
        'layout-fixed': this.fixed
      }
    }
  }
}
</script>

<style lang="scss">
.vuestic-layout {
  .content-wrap {
    margin-left: $content-wrap-ml;
    transition: margin-left 0.3s ease;
    padding: $layout-padding $layout-padding-right $content-wrap-pb 0;
    @media (max-width: 1368px) {
      margin-left: calc(24px + 180px + 35px);
    }
    .pre-loader {
      position: absolute;
      left: $vuestic-preloader-left;
      top: $vuestic-preloader-top;
    }
    @include media-breakpoint-down(md) {
      padding: $content-mobile-wrap;
      margin-left: 0;
      .sidebar-hidden & {
        margin-left: 0;
        padding-top: $content-mobile-wrap-sb-top;
      }
    }
  }
  &-fixed {
    .content-wrap {
      padding-right: $layout-padding-right;
      padding-top: $sidebar-top;
      @include media-breakpoint-down(md) {
        padding: $content-mobile-wrap-fixed-layout;
        margin-left: 0;
      }
    }
  }

  .made-by-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: $made-by-footer-pb;
  }
}
</style>

/* RTL STYLES */
<style lang="scss">
.vuestic-layout {
  &.rtl {
    direction: rtl;
    .content-wrap {
      transition: margin-right 0.3s ease;
      margin-left: unset;
      margin-right: $content-wrap-ml;
      @media (max-width: 1368px) {
        margin-right: calc(24px + 180px + 35px);
      }
      padding: $layout-padding 0 $content-wrap-pb $layout-padding-right;
      @include media-breakpoint-down(md) {
        padding: $content-mobile-wrap;
        margin-right: 0;
        .sidebar-hidden & {
          margin-right: 0;
          padding-top: $content-mobile-wrap-sb-top;
        }
      }
    }
    .vuestic-sidebar.sidebar-hidden + .content-wrap {
      margin-left: 0 !important;
    }
  }
  &-fixed {
    .content-wrap {
      padding-left: $layout-padding-right;
      padding-top: $sidebar-top;
      @include media-breakpoint-down(md) {
        padding: $content-mobile-wrap-fixed-layout;
        margin-right: 0;
      }
    }
  }
}
</style>
