import statsService from '../../../services/stats.service'

const state = {
  balance: {},
  balanceLoading: false,
  balanceErrorMessage: '',
  balanceError: null
}

const getters = {
  balance(state) {
    return state.balance
  },
  balanceLoading(state) {
    return state.balanceLoading
  },
  balanceErrorMessage(state) {
    return state.balanceErrorMessage
  },
  balanceError(state) {
    return state.balanceError
  }
}

const mutations = {
  setBalance(state, payload) {
    state.balance = payload
  },
  setBalanceLoading(state, payload) {
    state.balanceLoading = payload
  },
  setBalanceErrorMessage(state, payload) {
    state.balanceErrorMessage = payload
  },
  setBalanceError(state, payload) {
    state.balanceError = payload
  }
}

const actions = {
  async fetchBalance({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setBalanceLoading', true)
      const balance = await statsService.getBalance()
      commit('setBalanceErrorMessage', '')
      commit('setBalance', balance)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setBalanceErrorMessage', errorMessage)
      commit('setBalanceError', errorResponse)
    } finally {
      if (!silent) commit('setBalanceLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
