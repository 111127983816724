export const digits = [
  {
    key: '1',
    value: '1',
    keyNote: '',
    tone: '/sound/dtmf-1.mp3'
  },
  {
    key: '2',
    value: '2',
    keyNote: 'ABC',
    tone: '/sound/dtmf-2.mp3'
  },
  {
    key: '3',
    value: '3',
    keyNote: 'DEF',
    tone: '/sound/dtmf-3.mp3'
  },
  {
    key: '4',
    value: '4',
    keyNote: 'GHI',
    tone: '/sound/dtmf-4.mp3'
  },
  {
    key: '5',
    value: '5',
    keyNote: 'JKL',
    tone: '/sound/dtmf-5.mp3'
  },
  {
    key: '6',
    value: '6',
    keyNote: 'MNO',
    tone: '/sound/dtmf-6.mp3'
  },
  {
    key: '7',
    value: '7',
    keyNote: 'PQRS',
    tone: '/sound/dtmf-7.mp3'
  },
  {
    key: '8',
    value: '8',
    keyNote: 'TUV',
    tone: '/sound/dtmf-8.mp3'
  },
  {
    key: '9',
    value: '9',
    keyNote: 'WXYZ',
    tone: '/sound/dtmf-9.mp3'
  },
  {
    key: '*',
    value: '*',
    keyNote: '.',
    tone: '/sound/dtmf-star.mp3'
  },
  {
    key: '0',
    value: '0',
    keyNote: '+',
    tone: '/sound/dtmf-0.mp3'
  },
  {
    key: '#',
    value: '#',
    keyNote: '',
    tone: '/sound/dtmf-hash.mp3'
  }
]

export const extensionDetailsColors = {
  connected: 'bg-success',
  connecting: 'bg-warning',
  disconnected: 'bg-danger',
  break: 'bg-info'
}
