<template>
  <div class="crm-create-contact-form">
    <field
      class="mb-2 w-100"
      v-model="model.name"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.name.placeholder')
      "
      :error="errors.name"
    >
    </field>
    <field
      class="mb-2 w-100"
      v-model="model.phone"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.phone.placeholder')
      "
      :error="errors.phone"
    >
    </field>
    <field
      class="mb-2 w-100"
      v-model="model.email"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.email.placeholder')
      "
      :error="errors.email"
    >
    </field>
    <field
      class="mb-2 w-100"
      v-model="model.notes"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.notes.placeholder')
      "
      :error="errors.notes"
    >
    </field>
    <field
      class="mb-2 w-100"
      v-model="model.details"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.details.placeholder')
      "
      :error="errors.details"
    >
    </field>
    <field
      class="mb-2 w-100"
      v-model="model.alias"
      :placeholder="
        $t('webrtc.crm-contacts.create-contact.form.alias.placeholder')
      "
      :error="errors.alias"
    >
    </field>

    <button class="btn btn-primary" @click="onSaveContact" :disabled="loading">
      <half-circle-spinner
        slot="loading"
        :animation-duration="1500"
        :size="20"
        color="#fff"
        v-if="loading"
      />
      <span v-else>{{
        $t('webrtc.crm-contacts.create-contact.form.save')
      }}</span>
    </button>
  </div>
</template>

<script>
import CrmService from '../../../services/crm.service'
import { yupToKV } from '../../../utils/yup'
import Field from '../../Field.vue'
import { object, string } from 'yup'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  props: {
    callee: {
      type: Object
    }
  },
  data() {
    return {
      model: {
        name: this.callee.displayName,
        phone: this.callee.number,
        email: '',
        notes: '',
        details: '',
        alias: ''
      },
      errors: {},
      loading: false
    }
  },
  components: { Field, HalfCircleSpinner },
  methods: {
    async onSaveContact() {
      // remove all nulls
      this.model = Object.keys(this.model).reduce((accumulator, key) => {
        if (this.model[key] != null) {
          accumulator[key] = this.model[key]
        }

        return accumulator
      }, {})

      // validate on the model
      const schema = object().shape({
        name: string()
          .trim()
          .required(
            this.$t('webrtc.crm-contacts.create-contact.errors.name.required')
          ),
        phone: string()
          .matches(
            /^\d+$/,
            this.$t('webrtc.crm-contacts.create-contact.errors.phone.invalid')
          )
          .required(
            this.$t('webrtc.crm-contacts.create-contact.errors.phone.required')
          ),
        email: string()
          .trim()
          .email(
            this.$t('webrtc.crm-contacts.create-contact.errors.email.invalid')
          )
      })

      await schema.validate(this.model, { abortEarly: false }).catch(err => {
        this.errors = yupToKV(err)
      })

      if (!schema.isValidSync(this.model)) {
        return
      }

      this.errors = {}

      // send model to the backend
      try {
        this.loading = true
        // remove any empty fields
        const data = Object.keys(this.model).reduce((acc, key) => {
          const value = this.model[key]
          if (!!value.trim()) {
            acc[key] = value
          }
          return acc
        }, {})
        const url = await CrmService.createContact(data)
        window.open(url, '_blank')
        this.$emit('contactCreated', this.model)
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
