import statsService from '../../../services/stats.service'

const state = {
  totalAnsweredCalls: {
    config: {},
    labels: [],
    datasets: []
  },
  totalAnsweredCallsLoading: false,
  totalAnsweredCallsErrorMessage: '',
  totalAnsweredCallsError: null
}

const getters = {
  totalAnsweredCalls(state) {
    return state.totalAnsweredCalls
  },
  totalAnsweredCallsLoading(state) {
    return state.totalAnsweredCallsLoading
  },
  totalAnsweredCallsErrorMessage(state) {
    return state.totalAnsweredCallsErrorMessage
  },
  totalAnsweredCallsError(state) {
    return state.totalAnsweredCallsError
  }
}

const mutations = {
  setTotalAnsweredCalls(state, payload) {
    state.totalAnsweredCalls = payload
  },
  setTotalAnsweredCallsLoading(state, payload) {
    state.totalAnsweredCallsLoading = payload
  },
  setTotalAnsweredCallsErrorMessage(state, payload) {
    state.totalAnsweredCallsErrorMessage = payload
  },
  setTotalAnsweredCallsError(state, payload) {
    state.totalAnsweredCallsError = payload
  }
}

const actions = {
  async fetchTotalAnsweredCalls({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setTotalAnsweredCallsLoading', true)
      const totalAnsweredCalls = await statsService.getAnsweredCalls()
      commit('setTotalAnsweredCallsErrorMessage', '')
      commit('setTotalAnsweredCalls', totalAnsweredCalls)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setTotalAnsweredCallsErrorMessage', errorMessage)
      commit('setTotalAnsweredCallsError', errorResponse)
    } finally {
      if (!silent) commit('setTotalAnsweredCallsLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
