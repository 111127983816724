<template>
  <div class="extensions-pad d-flex flex-column">
    <half-circle-spinner
      v-if="isExtensionsLoading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <div class="m-auto text-danger" v-if="!!extensionsError">
      <p>{{ extensionsError }}</p>
    </div>
    <div
      class="extensions d-flex flex-column align-items-start p-1"
      v-if="!isExtensionsLoading && !extensionsError"
    >
      <h4 class="m-0 py-2">{{ $t('webrtc.choose-extension') }}</h4>
      <div
        class="extension p-3 w-100"
        :class="extension.status !== 'enabled' ? 'disabled' : ''"
        v-for="extension in extensions"
        :key="extension.id"
        @click="onExtensionLogin(extension)"
      >
        <div class="d-flex justify-content-between w-100 text-left">
          <span>
            <b>{{ extension.name }}</b>
          </span>
          <span class="text-primary">
            {{ extension.ext }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExtensionService from '../../services/extension.service.js'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      extensions: [],
      isExtensionsLoading: false,
      extensionsError: ''
    }
  },
  mounted() {
    this.getExtensions()
  },
  computed: {
    ...mapGetters(['allExtensions'])
  },
  watch: {
    allExtensions(value) {
      if (value.length > 0) {
        this.extensions = value
      }
    }
  },
  methods: {
    async getExtensions() {
      if (this.allExtensions.length > 0) {
        this.extensions = this.allExtensions
        return
      }
      try {
        this.isExtensionsLoading = true
        const extensions = await ExtensionService.getAllExtensions()
        this.extensions = extensions
      } catch (error) {
        if (!!error.response) this.extensionsError = error.response.data.message
        else this.extensionsError = error.message
      } finally {
        this.isExtensionsLoading = false
      }
    },
    async onExtensionLogin(extension) {
      if (extension.status !== 'enabled') return
      const extensionLoginDetails = await ExtensionService.getExtensionLoginDetails(
        extension.id
      )
      this.$emit('extensionLogin', {
        ...extension,
        ...extensionLoginDetails.agent
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.extensions-pad {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .extension {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
