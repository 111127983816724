import { api } from '../axios'

const getAllExtensions = async () => {
  const res = await api.get(`extension`)
  return res.data
}
const getAllExtensionsNumbers = async () => {
  const res = await api.get(`extension/list`)
  return res.data
}
const getOneExtension = async id => {
  const res = await api.get(`extension/show/${id}`)
  return res.data
}
const getExtensionLoginDetails = async extensionId => {
  const res = await api.get(`extension/login/${extensionId}`)
  return res.data
}

const getMonitorExtensions = async () => {
  const res = await api.get(`extension/monitor`)
  return res.data
}

const createExtension = async extension => {
  const res = await api.post(`extension`, extension)
  return res.data
}
const updateExtension = async (extensionId, extension) => {
  const res = await api.put(`extension`, {
    extensionId: +extensionId,
    ...extension
  })
  return res.data
}
const deleteExtension = async extensionId => {
  const res = await api.delete(`extension/${extensionId}`)
  return res.data
}
const getRecommendedExtensionNumber = async extension => {
  const res = await api.get(`extension/recommend-number`, extension)
  return res.data
}
const showPassword = async extensionId => {
  const res = await api.get(`extension/show-password`, {
    extensionId: +extensionId
  })
  return res.data
}

const getCallId = async (extension, direction) => {
  const res = await api.get(
    `extension/agent/calls/search-live?phone=${encodeURIComponent(
      extension
    )}&direction=${direction}`
  )
  return res.data
}

const ExtensionService = {
  getAllExtensions,
  getAllExtensionsNumbers,
  getOneExtension,
  getExtensionLoginDetails,
  getMonitorExtensions,
  createExtension,
  updateExtension,
  deleteExtension,
  getRecommendedExtensionNumber,
  showPassword,
  getCallId
}

export default ExtensionService
