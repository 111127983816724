<template>
  <div class="language-toggle flex-center">
    <a @click="setLanguage()" :title="title">
      <span class="flag-icon flag-icon-large" :class="flag" />
    </a>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'language-toggle',
  data() {
    return {
      isShown: false
    }
  },
  computed: {
    flag() {
      return Vue.i18n.locale() === 'en' ? 'flag-icon-sa' : 'flag-icon-us'
    },
    title() {
      return Vue.i18n.locale() === 'en' ? 'Arabic' : 'English'
    }
  },
  mounted() {},
  methods: {
    setLanguage() {
      let locale = Vue.i18n.locale() === 'en' ? 'ar' : 'en'
      Vue.i18n.set(locale)
      localStorage.setItem('locale', locale)
      document.body.style.opacity = 0
      location.reload()
    }
  }
}
</script>

<style lang="scss">
@import '../../../../../vuestic-theme/vuestic-sass/resources/resources';
@import '~flag-icon-css/css/flag-icon.css';

.language-toggle {
  cursor: pointer;
  .flag-icon-large {
    display: block;
    width: 31px;
    height: 23px;
  }
}
</style>
