<template>
  <div
    class="table-responsive vuestic-data-table"
    :class="{ 'data-loading': loading }"
  >
    <div
      class="d-flex align--center datatable-controls"
      v-if="
        actionsType !== 'invoices' &&
          actionsType !== 'survey_cdrs' &&
          actionsType !== 'auto-dialer-cdrs'
      "
    >
      <items-per-page
        :options="itemsPerPage"
        :label="$t('shared.table.per-page')"
        :defaultPerPage="defaultPerPageComputed"
        @items-per-page="onItemsPerPage"
        v-show="perPageSelectorShown"
      />
      <button
        class="mx-2 rounded btn btn-micro btn-primary"
        @click="fetchExtensionsForFilter()"
        v-if="actionsType == 'extensions' || actionsType == 'reporting'"
      >
        {{ $t('shared.table.filter-by') }}
      </button>
      <filter-bar
        class="mx-auto"
        @input="onFilterSet"
        :label="filterInputLabel"
        v-show="filterInputShown"
      />
      <div
        class="d-flex additionalTableControl"
        :class="actionsWide ? ' w-100 justify-content-between' : ''"
        v-if="additionalActions"
      >
        <slot name="additionalTableControl"></slot>
      </div>
    </div>
    <div v-show="loading" class="data-table-loading">
      <slot name="loading">
        <spring-spinner
          slot="loading"
          :animation-duration="2500"
          :size="70"
          color="#008ecf"
        />
      </slot>
    </div>
    <vuetable
      ref="vuetable"
      :reloadTable="reloadTable"
      :apiUrl="apiUrl"
      :apiMode="apiMode"
      :httpFetch="httpFetch"
      :httpOptions="httpOptions"
      :fields="tableFields"
      :data-manager="dataManager"
      :css="css.table"
      :detail-row-component="
        actionsType == 'extensions' ? 'detail-row' : undefined
      "
      :paginationPath="paginationPathComputed"
      :appendParams="moreParams"
      :perPage="perPage"
      :queryParams="queryParams"
      :noDataTemplate="noDataTemplate"
      :row-class="actionsType == 'extensions' ? onRowClass : undefined"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="onLoading"
      @vuetable:loaded="onLoaded"
      @vuetable:cell-clicked="onCellClicked"
    >
      <template
        slot="extensions_actions"
        slot-scope="props"
        v-if="
          actions && actionsType == 'extensions' && !props.rowData.isDeleted
        "
      >
        <div class="table-button-container">
          <img
            :src="require('@/assets/images/icon-edit.svg')"
            class="datatable-btns"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="editExtension(props.rowData.id)"
          />
          <img
            :src="
              props.rowData.status
                ? require('@/assets/images/icon-deactivate_extension.svg')
                : require('@/assets/images/icon-activate_extension.svg')
            "
            class="datatable-btns"
            :alt="props.rowData.status ? 'Deactivate' : 'Activate'"
            :title="props.rowData.status ? 'Deactivate' : 'Activate'"
            @click="
              openPopup({ id: props.rowData.id, status: props.rowData.status })
            "
          />
        </div>
      </template>
      <template
        slot="bridges_actions"
        slot-scope="props"
        v-if="
          props.rowData.callStatus == 'scheduled' &&
            props.rowData.deleted_at == null
        "
      >
        <div class="table-button-container">
          <img
            :src="require('@/assets/images/icon-edit.svg')"
            class="datatable-btns"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="editBridge(props.rowData.callId)"
          />

          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('bridge-delete', props.rowData.callId)"
          ></i>
        </div>
      </template>
      <template slot="surveys_actions" slot-scope="props">
        <div class="table-button-container">
          <i
            class="datatable-delete_btn fa fa-eye"
            @click="viewSurvey(props.rowData.id)"
          ></i>
          <!-- <img
            :src="require('@/assets/images/icon-edit.svg')"
            class="datatable-btns"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="editSurvey(props.rowData.id)"
          /> -->

          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('survey-delete', props.rowData.id)"
          ></i>
          <img
            :src="require('@/assets/images/status-on.svg')"
            width="40"
            style="cursor:pointer"
            v-if="props.rowData.status"
            @click="deactivateSurveyStatus(props.rowData.id)"
          />
          <img
            :src="require('@/assets/images/status-off.svg')"
            width="40"
            style="cursor:pointer"
            v-else
            @click="activateSurveyStatus(props.rowData.id)"
          />
        </div>
      </template>
      <template slot="dial_actions" slot-scope="props">
        <div class="table-button-container">
          <router-link
            :to="{
              name: 'auto-dialer-statistics',
              params: { id: props.rowData.id }
            }"
          >
            <i class="datatable-delete_btn fa fa-eye"></i>
          </router-link>
          <router-link
            :to="{
              name: 'auto-dialer-edit',
              params: { id: props.rowData.id }
            }"
          >
            <img
              :src="require('@/assets/images/icon-edit.svg')"
              class="datatable-btns"
              :alt="$t('buttons.edit')"
              :title="$t('buttons.edit')"
            />
          </router-link>
        </div>
      </template>
      <template slot="token_actions" slot-scope="props">
        <div class="table-button-container">
          <img
            :src="require('@/assets/images/icon-edit.svg')"
            class="datatable-btns"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="$emit('edit-token', props.rowData.uuid)"
          />

          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('delete-token', props.rowData.uuid)"
          ></i>
        </div>
      </template>
      <template slot="orders_actions" slot-scope="props">
        <div class="table-button-container">
          <img
            v-if="
              props.rowData.status == 'completed' ||
                props.rowData.status == 'finished'
            "
            :src="require('@/assets/images/download-batch.png')"
            width="100"
            style="cursor: pointer"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="downloadBatch(props.rowData.id, props.rowData.filename)"
          />
        </div>
      </template>
      <!--
      // ? pending-import  =>  show loading icon
      // ? ready => show edit / delete
      // ? time-failure / failure / partial-import => show edit /show delete
      // ? partial-import=> show message with ignore(request)
      // ? in-process => play / pause
      // ? completed - finished => show download button
      // ? 'time-failure','failure','partial-import' return  reasons  -->
      <template slot="batch_actions" slot-scope="props">
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="table-button-container"
            v-if="
              props.rowData.status == 'time-failure' ||
                props.rowData.status == 'failure' ||
                props.rowData.status == 'partial-import' ||
                props.rowData.status == 'ready'
            "
          >
            <router-link
              :to="{ name: 'orders-edit', params: { id: props.rowData.id } }"
            >
              <i class="datatable-delete_btn fa fa-pencil"></i>
            </router-link>
          </div>
          <div
            class="table-button-container"
            v-if="props.rowData.status == 'in-process'"
          >
            <i
              v-if="props.rowData.batch_status == 'playing'"
              class="datatable-play_btn fa fa-pause"
              @click="pauseBatch(props.rowData.id)"
            ></i>
            <i
              v-if="props.rowData.batch_status == 'paused'"
              class="datatable-play_btn fa fa-play"
              @click="playBatch(props.rowData.id)"
            ></i>
          </div>
          <div
            class="table-button-container"
            v-if="
              props.rowData.status == 'pending-import' ||
                props.rowData.status == 'time-failure' ||
                props.rowData.status == 'failure' ||
                props.rowData.status == 'partial-import' ||
                props.rowData.status == 'ready'
            "
          >
            <i
              class="datatable-delete_btn fa fa-trash"
              @click="openDeleteBatch(props.rowData.id)"
            ></i>
          </div>
        </div>
      </template>
      <template
        slot="phonebook_actions"
        slot-scope="props"
        v-if="actions && actionsType == 'phonebook'"
      >
        <div class="table-button-container">
          <router-link :to="`update/${props.rowData.uuid}`">
            <img
              :src="require('@/assets/images/icon-edit.svg')"
              class="datatable-btns"
              :alt="$t('buttons.edit')"
              :title="$t('buttons.edit')"
            />
          </router-link>
          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('phonebook-delete', props.rowData.uuid)"
          ></i>
        </div>
      </template>
      <template
        slot="campaigns_actions"
        slot-scope="props"
        v-if="actions && actionsType == 'campaigns'"
      >
        <div class="table-button-container">
          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('campaign-delete', props.rowData.uuid)"
            v-if="props.rowData.status.name == 'scheduled'"
          ></i>
          <i
            class="datatable-btns fa fa-file-excel-o"
            @click="$router.push({ path: `cdr/${props.rowData.uuid}` })"
            v-if="props.rowData.status.name == 'completed'"
          ></i>
        </div>
      </template>
      <template
        slot="contacts_actions"
        slot-scope="props"
        v-if="actions && actionsType == 'contacts'"
      >
        <div class="table-button-container">
          <img
            :src="require('@/assets/images/icon-edit.svg')"
            class="datatable-btns"
            :alt="$t('buttons.edit')"
            :title="$t('buttons.edit')"
            @click="$emit('contact-update', props.rowData)"
          />
          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('contact-delete', props.rowData.uuid)"
          ></i>
        </div>
      </template>
      <template
        slot="voiceMessages_actions"
        slot-scope="props"
        v-if="actions && actionsType == 'voiceMessages'"
      >
        <div class="table-button-container">
          <i
            class="datatable-delete_btn fa fa-trash"
            @click="$emit('voicemessage-delete', props.rowData.id)"
          ></i>
        </div>
      </template>
      <template
        slot="packages_actions"
        slot-scope="props"
        v-if="actions && actionsType == 'packages'"
      >
        <div class="table-button-container">
          <span class="btn-plain" @click="subscribe(props.rowData.plan_id)">
            {{ $t('buttons.subscribe') }}
          </span>
        </div>
      </template>
    </vuetable>
    <div class="flex-center justify--space-between px-2">
      <span v-if="recordsTotal">
        {{ recordsTotal }}
      </span>
      <vuetable-pagination
        ref="pagination"
        :css="css.pagination"
        :onEachSide="onEachSide"
        @vuetable-pagination:change-page="onChangePage"
      />
    </div>
    <app-popup v-if="actionsType == 'extensions' && pbxSubscription">
      <template slot="popup-content">
        <div v-if="extensionActive">
          <h5 class="popup-title">
            Are you sure you want to deactivate this Extension?
          </h5>
          <span>Deactivating the Extension will block access to it.</span>
          <span>You can re-activate it later.</span>
        </div>
        <div v-else>
          <h5 class="popup-title">
            Are you sure you want to re-activate this Extension?
          </h5>
          <span>
            Your agents will be able to log in using the same credentials.
          </span>
        </div>
        <div class="justify--center mt-4 w-100 va-row" v-if="!formLoading">
          <div class="flex md5 xs12">
            <button
              type="button"
              class="btn-block btn btn-micro btn-primary"
              @click="toggleStatus"
            >
              Yes
            </button>
          </div>
        </div>
        <div class="justify--center w-100 va-row" v-if="!formLoading">
          <div class="flex md5 xs12">
            <button
              type="button"
              class="btn-block btn btn-danger btn-micro"
              @click="dismissPopup"
            >
              No
            </button>
          </div>
        </div>
        <div class="justify--center mt-4 w-100 va-row" v-if="formLoading">
          <spring-spinner
            slot="loading"
            :animation-duration="2500"
            :size="50"
            color="#008ecf"
          />
        </div>
      </template>
    </app-popup>
    <app-popup v-if="deleteBatchMode">
      <template slot="popup-content">
        <h5 class="popup-title">
          Are you sure you want to delete this Batch?
        </h5>
        <div class="justify--center mt-3 w-100 va-row">
          <half-circle-spinner
            slot="loading"
            :animation-duration="1500"
            :size="50"
            color="#74b9ff"
            v-show="deleteLoading"
            class="mt-2"
          />
          <div class="flex md6" v-if="!deleteLoading">
            <button
              type="button"
              class="btn-block btn btn-danger btn-micro"
              @click="deleteHandler"
            >
              Delete
            </button>
          </div>
        </div>
      </template>
    </app-popup>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import FilterBar from './datatable-components/FilterBar.vue'
import ItemsPerPage from './datatable-components/ItemsPerPage.vue'
import DefaultPerPageDefinition from './data/items-per-page-definition'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import QueryParams from './data/query-params'
import Vue from 'vue'
import http from '../../../axios'
import DataTableStyles from '../vuestic-datatable/data/data-table-styles'
import SpringSpinner from 'epic-spinners/src/components/lib/SpringSpinner'
import { mapActions, mapGetters } from 'vuex'
import DetailRow from '@/components/extensions/components/detail-row'
import FileSaver from 'file-saver'
Vue.component('detail-row', DetailRow)

export default {
  name: 'vuestic-data-table',
  components: {
    SpringSpinner,
    FilterBar,
    Vuetable,
    VuetablePagination,
    ItemsPerPage,
    HalfCircleSpinner
  },
  props: {
    reloadTable: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Boolean,
      default: false
    },
    actionsType: {
      type: String
    },
    additionalActions: {
      type: Boolean,
      default: false
    },
    actionsWide: {
      type: Boolean,
      default: false
    },
    apiUrl: {
      type: String
    },
    paginationOn: {
      type: Boolean,
      default: true
    },
    onFilterCustom: {
      type: String,
      default: null
    },
    httpFetch: {
      type: Function,
      default: null
    },
    httpOptions: {
      type: Object,
      default: () => {}
    },
    filterQuery: {
      type: String,
      default: 'filter'
    },
    tableFields: {
      type: Array,
      required: true
    },
    itemsPerPage: {
      type: Array,
      default: () => DefaultPerPageDefinition.itemsPerPage
    },
    perPageSelectorShown: {
      type: Boolean,
      default: true
    },
    filterInputShown: {
      type: Boolean,
      default: true
    },
    filterInputLabel: {
      type: String,
      default: 'Search'
    },
    itemsPerPageLabel: {
      type: String
    },
    defaultPerPage: {
      type: Number,
      default: DefaultPerPageDefinition.itemsPerPage[0].value
    },
    onEachSide: {
      type: Number,
      default: 2
    },
    apiMode: {
      type: Boolean,
      default: true
    },
    tableData: {
      type: Object,
      default() {
        return {
          data: []
        }
      }
    },
    dataModeFilterableFields: {
      type: Array,
      default: () => []
    },
    sortFunctions: {
      type: Object
    },
    paginationPath: {
      type: String,
      default: ''
    },
    queryParams: {
      type: Object,
      default: () => QueryParams
    },
    appendParams: {
      type: Object,
      default() {
        return {}
      }
    },
    newPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      extension: {},
      extensionActive: true,
      extensionID: 0,
      formLoading: false,
      show: true,
      perPage: 0,
      colorClasses: {},
      filterText: '',
      dataCount: 0,
      css: DataTableStyles,
      loading: false,
      noDataTemplate: '',
      recordsTotal: '',
      deleteBatchMode: false,
      currentBatchID: null,
      deleteLoading: false
    }
  },
  watch: {
    onFilterCustom: function(val) {
      this.onFilterSet(val)
    },
    reloadTable: function(val) {
      if (val) {
        this.$refs.vuetable.refresh()
        this.$refs.vuetable.normalizeFields()
      }
    },
    reloadTabledata: function(val) {
      if (val) {
        this.$refs.vuetable.refresh()
        this.$refs.vuetable.normalizeFields()
        this.$store.commit('setReloadTabledata', false)
      }
    },
    locale: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.$refs.vuetable.resetData()
          this.$refs.vuetable.loadData()
          this.$refs.vuetable.refresh()
          this.$refs.vuetable.normalizeFields()
        }, 0)
      }
    }
  },
  computed: {
    ...mapGetters(['reloadTabledata', 'pbxSubscription']),
    locale() {
      return Vue.i18n.locale()
    },
    controlsAlignmentClass() {
      return {
        'justify--space-between': this.filterInputShown,
        'justify--end': !this.filterInputShown
      }
    },
    moreParams() {
      // HACK
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.appendParams[this.filterQuery] = this.filterText
      return this.appendParams
    },
    dataModeFilterableFieldsComputed() {
      const dataItem = this.tableData.data[0] || {}
      const filterableFields = this.dataModeFilterableFields
      if (!filterableFields.length) {
        const itemFields = Object.keys(dataItem)
        itemFields.forEach(field => {
          if (typeof dataItem[field] !== 'object') {
            filterableFields.push(field)
          }
        })
      }

      return filterableFields
    },
    filteredTableData() {
      const txt = new RegExp(this.filterText, 'i')

      let filteredData = this.tableData.data.slice()

      filteredData = this.tableData.data.filter(item => {
        return this.dataModeFilterableFieldsComputed.some(field => {
          const val = item[field] + ''
          return val.search(txt) >= 0
        })
      })
      return {
        data: filteredData
      }
    },
    defaultPerPageComputed() {
      let defaultPerPage = DefaultPerPageDefinition.itemsPerPage[0].value
      if (this.$options.propsData.defaultPerPage) {
        defaultPerPage = this.$options.propsData.defaultPerPage
      } else if (this.$options.propsData.itemsPerPage) {
        defaultPerPage = this.$options.propsData.itemsPerPage[0].value
      }

      return defaultPerPage
    },
    paginationPathComputed() {
      return this.apiMode ? this.paginationPath : 'pagination'
    }
  },

  created() {
    this.perPage = this.defaultPerPageComputed
  },

  mounted() {
    this.$emit('initialized', this.$refs.vuetable)
  },

  methods: {
    ...mapActions([
      'subscribeToPackage',
      'toggleExtensionStatus',
      'fetchAllExtensions',
      'checkExtensionAvailability'
    ]),
    async fetchExtensionsForFilter() {
      this.$store.dispatch('toggleFilterbar', true)
      await this.$store.dispatch('fetchAllExtensions')
    },
    async toggleStatus() {
      this.formLoading = true
      const response = await this.toggleExtensionStatus(this.extensionID)
      if (response.success) {
        this.toast(response.data.message)
      }
      this.formLoading = false
      this.$store.dispatch('togglePopup', false)
      this.$refs.vuetable.refresh()
      this.$refs.vuetable.normalizeFields()
    },
    openPopup({ id, status }) {
      status ? (this.extensionActive = true) : (this.extensionActive = false)
      this.extensionID = id
      this.$store.dispatch('togglePopup', true)
    },
    dismissPopup() {
      this.$store.dispatch('togglePopup', false)
    },
    async pauseBatch(id) {
      const response = await http.post(
        `ecommercewa/api/v1/order/confirmation/upload/update-status`,
        {
          file_id: id
        }
      )
      if (response.data.success) {
        this.toast(response.data.data.message)

        this.$refs.vuetable.reload()
      }
    },
    async playBatch(id) {
      const response = await http.post(
        `ecommercewa/api/v1/order/confirmation/upload/update-status`,
        {
          file_id: id
        }
      )
      if (response.data.success) {
        this.toast(response.data.data.message)

        this.$refs.vuetable.reload()
      }
    },
    openDeleteBatch(id) {
      this.currentBatchID = id
      this.deleteBatchMode = true
      this.$store.dispatch('togglePopup', true)
    },

    async deleteHandler() {
      this.deleteLoading = true
      const response = await http.delete(
        `ecommercewa/api/v1/order/confirmation/upload/${this.currentBatchID}`
      )
      if (response.data.success) {
        this.toast(response.data.data.message)
        this.$store.dispatch('togglePopup', false)
        this.$refs.vuetable.refresh()
        this.$refs.vuetable.reload()
        this.currentBatchID = null
        this.deleteBatchMode = false
      }
      this.deleteLoading = false
    },
    async onCellClicked(data, field, event) {
      if (this.actionsType == 'extensions') {
        if (data.status) {
          if (!this.$refs.vuetable.isVisibleDetailRow(data.id)) {
            this.loading = true
            const response = await this.checkExtensionAvailability(data.id)
            this.loading = false
            if (response.success) {
              this.$refs.vuetable.toggleDetailRow(data.id)
            } else {
              this.$refs.vuetable.refresh()
              this.$refs.vuetable.normalizeFields()
            }
          } else {
            this.$refs.vuetable.toggleDetailRow(data.id)
          }
        }
      }
    },
    onRowClass(data) {
      if (data.status && !data.isDeleted) {
        if (!this.$refs.vuetable.isVisibleDetailRow(data.id)) {
          return 'collapsed'
        }
        return 'expanded'
      } else if (data.isDeleted) {
        return 'disabled'
      }
      return ''
    },
    async editExtension(id) {
      const response = await this.checkExtensionAvailability(id)
      if (response.success) {
        this.$router.push({ path: `update/${id}` })
      } else {
        this.$refs.vuetable.refresh()
        this.$refs.vuetable.normalizeFields()
      }
    },
    editBridge(id) {
      this.$router.push({ path: `update/${id}` })
    },
    viewSurvey(id) {
      this.$router.push({ name: 'survey-cdrs-charts', params: { id: id } })
    },
    editSurvey(id) {
      this.$router.push({ name: 'survey-edit', params: { id: id } })
    },
    logUUID(id) {
      console.log(id)
    },
    activateSurveyStatus(id) {
      try {
        this.$swal({
          title: 'Are you sure you want to activate this Survey?',
          icon: 'warning',
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            const response = http.post(
              `call/survey/api/v1/survey/status/change`,
              {
                survey_id: id
              }
            )
            this.$swal('Activated!', `Survey has been activated.`, 'success')
            setTimeout(() => {
              this.$refs.vuetable.refresh()
              this.$refs.vuetable.reload()
            }, 500)
          }
        })
      } catch (error) {
        if (error.response) {
          return {
            success: false,
            errors: {}
          }
        } else {
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    deactivateSurveyStatus(id) {
      try {
        this.$swal({
          title: 'Are you sure you want to deactivate this Survey?',
          icon: 'warning',
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            const response = http.post(
              `call/survey/api/v1/survey/status/change`,
              {
                survey_id: id
              }
            )
            this.$swal('Deactivated!', `Brand has been deactivated.`, 'success')
            setTimeout(() => {
              this.$refs.vuetable.refresh()
              this.$refs.vuetable.reload()
            }, 500)
          }
        })
      } catch (error) {
        if (error.response) {
          return {
            success: false,
            errors: {}
          }
        } else {
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async downloadBatch(id, filename) {
      const response = await http
        .get(
          `${process.env.VUE_APP_WEBSITE_URL}ecommercewa/api/v1/cdr/report/batch/${id}`,
          {
            responseType: 'arraybuffer'
          }
        )
        .then(function(response) {
          var blob = new Blob([response.data], {
            type: response.headers['content-type']
          })
          FileSaver.saveAs(blob, filename)
        })
    },
    async subscribe(plan_id) {
      const res = await this.subscribeToPackage(plan_id)
      if (res.data.success) {
        this.toast(res.data.data.message)
      }
      this.$refs.vuetable.refresh()
      this.$refs.vuetable.normalizeFields()
    },
    onFilterSet(filterText) {
      this.filterText = filterText
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onItemsPerPage(itemsPerPageValue) {
      this.perPage = itemsPerPageValue
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onPaginationData(paginationData) {
      // console.log(paginationData)

      // if (this.newPagination) {
      //   const pagination = {
      //     current_page: paginationData.page,
      //     data: paginationData.data,
      //     from: paginationData.start,
      //     last_page: paginationData.finish,
      //     per_page: this.perPage,
      //     to: paginationData.finish,
      //     total: paginationData.count * this.perPage
      //   }
      //   this.$refs.pagination.setPaginationData(pagination)
      // } else {
      // }

      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    dataManager(sortOrder, pagination) {
      let data = this.filteredTableData.data
      let sortFunctions = this.sortFunctions

      if (sortOrder.length > 0) {
        data.sort(function(item1, item2) {
          const sortField = sortOrder[0].sortField
          let fn = sortFunctions[sortField]
          if (fn) {
            return fn(item1[sortField], item2[sortField])
          }
        })

        if (sortOrder[0].direction === 'desc') {
          data.reverse()
        }
      }

      pagination = this.$refs.vuetable.makePagination(data.length)

      return {
        pagination: pagination,
        data: data.slice(pagination.from - 1, pagination.to)
      }
    },
    onLoading() {
      this.noDataTemplate = ''
      this.loading = true
      this.$emit('vuestic:loading')
    },
    onLoaded() {
      this.noDataTemplate = this.$t('tables.dataTable.noDataAvailable')
      this.loading = false
      this.setRowsNumber()
      this.$emit('vuestic:loaded')
      this.$emit('pagination-data-updated', {
        page: this.$refs.vuetable.tablePagination.current_page,
        per_page: this.$refs.vuetable.tablePagination.per_page
      })
    },
    setRowsNumber() {
      if (this.$refs.pagination.tablePagination) {
        if (this.$refs.pagination.tablePagination.total) {
          this.recordsTotal = this.$t('tables.dataTable.recordsTotal')
            .replace(':from', this.$refs.pagination.tablePagination.from)
            .replace(':to', this.$refs.pagination.tablePagination.to)
            .replace(':total', this.$refs.pagination.tablePagination.total)
        } else {
          this.recordsTotal = this.$t('tables.dataTable.recordsTotalShort')
            .replace(':from', this.$refs.pagination.tablePagination.from)
            .replace(':to', this.$refs.pagination.tablePagination.to)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.vuestic-data-table {
  min-height: 24rem;
  padding: 2px;
  .datatable-controls {
    background-color: $white;
    border-radius: 9px;
    padding: 15px 20px;
    margin-bottom: 30px;
    box-shadow: $widget-box-shadow;
    flex-wrap: wrap;
    .additionalTableControl {
      margin-left: auto;
    }
  }
  .table {
    box-shadow: $widget-box-shadow;
    border-radius: 9px;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      background-color: $blue;
      tr {
        color: $white;
        border-color: $blue;
        th {
          font-weight: 300;
          text-transform: capitalize;
          &:first-child {
            border-top-left-radius: 9px;
          }
          &:last-child {
            border-top-right-radius: 9px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid lighten($gray, 20%);
        }
        &:last-child {
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          td {
            &:first-child {
              border-bottom-left-radius: 9px;
            }
            &:last-child {
              border-bottom-right-radius: 9px;
            }
            &.vuetable-empty-result {
              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;
            }
          }
        }
      }
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }

  @media (max-width: 1258px) {
    .pagination-link-btn:first-child,
    .pagination-link-btn:last-child {
      display: none;
    }

    .pagination-link-btn:nth-child(2) {
      border-top-left-radius: $btn-border-radius !important;
      border-bottom-left-radius: $btn-border-radius !important;
    }

    .pagination-link-btn:nth-last-child(2) {
      border-top-right-radius: $btn-border-radius !important;
      border-bottom-right-radius: $btn-border-radius !important;
    }
  }

  @media (max-width: 991px) {
    .datatable-controls {
      div:not(.btn-with-icon-content) {
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 576px) {
    .hide-not-focused-btn:not(.focus) {
      display: none;
    }
  }

  .data-table-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
  }
  .btn-plain {
    color: $main-light;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.2s ease;
  }
  .btn-plain:hover {
    cursor: pointer;
    font-weight: bold;
    color: darken($main-light, 10%);
  }
  .datatable-btns {
    width: 25px;
    height: 25px;
    margin: 0 5px;
    transform: scale(1);
    transition: transform 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
  .datatable-delete_btn {
    font-size: 25px;
    margin: 0 5px;
    vertical-align: middle;
    transform: scale(1);
    transition: all 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      color: $brand-danger;
    }
  }
  .datatable-play_btn {
    font-size: 15px;
    margin: 0 5px;
    vertical-align: middle;
    transform: scale(1);
    transition: all 0.1s ease-in;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      color: #008ecf;
    }
  }
}

.data-loading {
  opacity: 0.5;
  pointer-events: none;
}
tr.expanded,
tr.collapsed {
  &:hover {
    cursor: pointer;
  }
  td:first-child {
    position: relative;
  }
  td:first-child:after {
    content: '\25BC';
    position: absolute;
    right: 10px;
    color: $blue !important;
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in-out;
  }
}
tr.collapsed {
  td:first-child:after {
    transform: rotate(0deg);
  }
}
tr.disabled {
  color: $gray;
  cursor: default;
}
</style>

/* RTL STYLES */
<style lang="scss">
.rtl {
  .vuestic-data-table {
    .pagination-link-btn:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 1.875rem;
      border-bottom-right-radius: 1.875rem;
      .fa-angle-double-left::before {
        content: '<<';
      }
    }
    .pagination-link-btn:last-child {
      border-top-left-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .fa-angle-double-right::before {
        content: '>>';
      }
    }
    .pagination-link-btn:nth-child(2) {
      .fa-angle-left::before {
        content: '<';
      }
    }

    .pagination-link-btn:nth-last-child(2) {
      .fa-angle-right::before {
        content: '>';
      }
    }
  }
  .vuestic-data-table {
    .table {
      thead {
        tr {
          th {
            &:first-child {
              border-top-right-radius: 9px;
              border-top-left-radius: 0px;
            }
            &:last-child {
              border-top-left-radius: 9px;
              border-top-right-radius: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
