import moment from 'moment'

const getCallLog = ext => {
  try {
    const logString = localStorage.getItem(`callLog.${ext}`)
    const log = JSON.parse(logString)

    return log || []
  } catch (error) {
    return []
  }
}

export const getLatestCallLog = ext => {
  return [...getCallLog(ext)].reverse()
}
export const setCallLog = (callLog, ext) => {
  localStorage.setItem(`callLog.${ext}`, JSON.stringify(callLog))
}
export const addCallToLog = (call, ext) => {
  let callLog = getCallLog(ext)
  const callIndex = callLog.findIndex(({ number }) => number === call.number)
  const time = moment().format('YYYY-MM-DD HH:mm A')
  const timestamp = new Date().getTime()
  const singleCall = {
    id: `call-${timestamp}`,
    time: time,
    ...call
  }

  if (callIndex !== -1) {
    // if the number exists
    const singleLog = callLog[callIndex]
    singleLog.time = time
    singleLog.stats = {
      ...singleLog.stats,
      [singleCall.type]: singleLog.stats[singleCall.type] + 1
    }
    singleLog.calls.push(singleCall)

    // remove the call from calls array and add it at the end
    callLog.splice(callIndex, 1)
    callLog = [...callLog, singleLog]
  } else {
    const singleLog = {
      number: singleCall.number,
      name: singleCall.name,
      time: time,
      stats: {
        incoming: 0,
        outgoing: 0,
        missed: 0,
        rejected: 0,
        [singleCall.type]: 1
      },
      calls: [singleCall]
    }
    callLog = [...callLog, singleLog]
  }

  setCallLog(callLog, ext)
}

export const clearCallLog = ext => {
  localStorage.removeItem(`callLog.${ext}`)
}

export const deleteCallFromLog = (callId, ext) => {
  let callLog = getCallLog(ext)
  const callIndex = callLog.findIndex(({ calls }) =>
    calls.some(({ id }) => id === callId)
  )

  if (callIndex !== -1) {
    const singleLog = callLog[callIndex]
    const callIndexInCalls = singleLog.calls.findIndex(
      ({ id }) => id === callId
    )

    if (callIndexInCalls !== -1) {
      singleLog.calls.splice(callIndexInCalls, 1)
      singleLog.stats[singleLog.calls[callIndexInCalls].type] -= 1

      if (singleLog.calls.length === 0) {
        callLog.splice(callIndex, 1)
      } else {
        callLog[callIndex] = singleLog
      }

      setCallLog(callLog, ext)
    }
  }
}

export const updateCallInLog = (call, ext) => {
  let callLog = getCallLog(ext)
  const callIndex = callLog.findIndex(({ calls }) =>
    calls.some(({ id }) => id === call.id)
  )

  if (callIndex !== -1) {
    const singleLog = callLog[callIndex]
    const callIndexInCalls = singleLog.calls.findIndex(
      ({ id }) => id === call.id
    )

    if (callIndexInCalls !== -1) {
      singleLog.calls[callIndexInCalls] = call
      callLog[callIndex] = singleLog

      // calculate the stats again
      singleLog.stats = singleLog.calls.reduce(
        (acc, { type }) => {
          acc[type] = acc[type] + 1
          return acc
        },
        { incoming: 0, outgoing: 0, missed: 0, rejected: 0 }
      )

      setCallLog(callLog, ext)
    }
  }
}
