import statsService from '../../../services/stats.service'

const state = {
  extensionsCount: 0,
  extensionsCountLoading: false,
  extensionsCountErrorMessage: '',
  extensionsCountError: null
}

const getters = {
  extensionsCount(state) {
    return state.extensionsCount
  },
  extensionsCountLoading(state) {
    return state.extensionsCountLoading
  },
  extensionsCountErrorMessage(state) {
    return state.extensionsCountErrorMessage
  },
  extensionsCountError(state) {
    return state.extensionsCountError
  }
}

const mutations = {
  setExtensionsCount(state, payload) {
    state.extensionsCount = payload
  },
  setExtensionsCountLoading(state, payload) {
    state.extensionsCountLoading = payload
  },
  setExtensionsCountErrorMessage(state, payload) {
    state.extensionsCountErrorMessage = payload
  },
  setExtensionsCountError(state, payload) {
    state.extensionsCountError = payload
  }
}

const actions = {
  async fetchExtensionsCount({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setExtensionsCountLoading', true)
      const extensionsCount = await statsService.getExtensionsCount()
      commit('setExtensionsCountErrorMessage', '')
      commit('setExtensionsCount', extensionsCount)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setExtensionsCountErrorMessage', errorMessage)
      commit('setExtensionsCountError', errorResponse)
    } finally {
      if (!silent) commit('setExtensionsCountLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
