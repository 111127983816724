import { api } from '../axios'

const getAllAgents = async (page = 1, limit = 10) => {
  const res = await api.get(`agent?page=${page}&limit=${limit}`)
  return res.data
}
const getOneAgent = async agentId => {
  const res = await api.get(`agent/${agentId}/profile`)
  return res.data
}
const deleteAgent = async agentId => {
  const res = await api.delete(`agent/${agentId}`)
  return res.data
}
const restoreAgent = async agentId => {
  const res = await api.patch(`agent/${agentId}/restore`)
  return res.data
}
const createAgent = async agent => {
  const res = await api.post(`agent`, agent)
  return res.data
}
const updateAgent = async (agentId, agent) => {
  const res = await api.put(`agent/${agentId}`, agent)
  return res.data
}

const toggleBreak = async () => {
  const res = await api.post(`agent-break`)
  return res.data
}
const hasUnfinishedBreak = async () => {
  const res = await api.get(`agent-break/has-unfinished-break`)
  return res.data
}

const agentService = {
  getAllAgents,
  getOneAgent,
  deleteAgent,
  restoreAgent,
  createAgent,
  updateAgent,
  toggleBreak,
  hasUnfinishedBreak
}

export default agentService
