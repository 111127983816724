import statsService from '../../../services/stats.service'

const state = {
  todayCallDuration: {},
  todayCallDurationLoading: false,
  todayCallDurationErrorMessage: '',
  todayCallDurationError: null
}

const getters = {
  todayCallDuration(state) {
    return state.todayCallDuration
  },
  todayCallDurationLoading(state) {
    return state.todayCallDurationLoading
  },
  todayCallDurationErrorMessage(state) {
    return state.todayCallDurationErrorMessage
  },
  todayCallDurationError(state) {
    return state.todayCallDurationError
  }
}

const mutations = {
  setTodayCallDuration(state, payload) {
    state.todayCallDuration = payload
  },
  setTodayCallDurationLoading(state, payload) {
    state.todayCallDurationLoading = payload
  },
  setTodayCallDurationErrorMessage(state, payload) {
    state.todayCallDurationErrorMessage = payload
  },
  setTodayCallDurationError(state, payload) {
    state.todayCallDurationError = payload
  }
}

const actions = {
  async fetchTodayCallDuration({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setTodayCallDurationLoading', true)
      const todayCallDuration = await statsService.getTodayCallDuration()
      commit('setTodayCallDurationErrorMessage', '')
      commit('setTodayCallDuration', todayCallDuration)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setTodayCallDurationErrorMessage', errorMessage)
      commit('setTodayCallDurationError', errorResponse)
    } finally {
      if (!silent) commit('setTodayCallDurationLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
