import statsService from '../../../services/stats.service'

const state = {
  callDistribution: {
    config: {},
    labels: [],
    datasets: []
  },
  callDistributionLoading: false,
  callDistributionErrorMessage: '',
  callDistributionError: null
}

const getters = {
  callDistribution(state) {
    return state.callDistribution
  },
  callDistributionLoading(state) {
    return state.callDistributionLoading
  },
  callDistributionErrorMessage(state) {
    return state.callDistributionErrorMessage
  },
  callDistributionError(state) {
    return state.callDistributionError
  }
}

const mutations = {
  setCallDistribution(state, payload) {
    state.callDistribution = payload
  },
  setCallDistributionLoading(state, payload) {
    state.callDistributionLoading = payload
  },
  setCallDistributionErrorMessage(state, payload) {
    state.callDistributionErrorMessage = payload
  },
  setCallDistributionError(state, payload) {
    state.callDistributionError = payload
  }
}

const actions = {
  async fetchCallDistribution({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setCallDistributionLoading', true)
      const callDistribution = await statsService.getCallDistribution()
      commit('setCallDistributionErrorMessage', '')
      commit('setCallDistribution', callDistribution)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setCallDistributionErrorMessage', errorMessage)
      commit('setCallDistributionError', errorResponse)
    } finally {
      if (!silent) commit('setCallDistributionLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
