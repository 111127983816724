<template>
  <vuestic-layout :class="{ rtl }">
    <app-navbar :isOpen="isSidebarOpen" @update:isOpen="toggleSidebar" />
    <app-sidebar :isOpen="isSidebarOpen" />
    <main
      slot="content"
      id="content"
      class="mb-5 content fluid gutter--lg va-layout"
      role="main"
    >
      <app-breadcrumbs />
      <div class="pre-loader--container" v-show="isLoading">
        <semipolar-spinner
          slot="loading"
          :animation-duration="1500"
          :size="150"
          color="#008ecf"
          class="pre-loader"
        />
      </div>
      <router-view></router-view>
    </main>
    <div slot="footer" class="text-center footer">
      <router-link
        :to="{ name: 'inno-support' }"
        class="inno-support"
        v-if="
          !isSidebarOpen && currentUser && currentUser.completeControlTicketing
        "
      >
        <img
          :src="require('@/assets/images/inno-support.png')"
          alt="INNO SUPPORT"
        />
        <span>
          {{ $t('sidebar.support') }}
        </span>
      </router-link>
      <p class="copyright-text">
        Copyright {{ new Date().getFullYear() }} &copy;
        <a href="https://innocalls.com/" target="_blank">Innocalls</a>
      </p>
      <WebRTC v-if="currentTenant && currentTenant.hasTenant"></WebRTC>
    </div>
  </vuestic-layout>
</template>

<script>
import Vue from 'vue'
import AppNavbar from '../components/admin/app-navbar/AppNavbar'
import AppSidebar from '../components/admin/app-sidebar/AppSidebar'
import VuesticLayout from '../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout'
import AppBreadcrumbs from '../components/admin/app-breadcrumbs/AppBreadcrumbs'
import SemipolarSpinner from 'epic-spinners/src/components/lib/SemipolarSpinner'
import WebRTC from '../containers/WebRTC'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AppNavbar,
    AppSidebar,
    VuesticLayout,
    AppBreadcrumbs,
    SemipolarSpinner,
    WebRTC
  },
  async mounted() {
    const locale = localStorage.getItem('locale') || 'en'
    Vue.i18n.set(locale)

    const token = localStorage.getItem('token')

    if (!!token) {
      const user = await this.fetchCurrentUser()
      this.$gates.setPermissions(user.permissions || [])
      this.fetchProviders()
    }
  },
  data() {
    return {
      isSidebarOpen: true,
      rtl: false,
      isVirtualPhoneOpen: false
    }
  },
  methods: {
    ...mapActions(['fetchProviders', 'fetchCurrentUser']),
    toggleSidebar(opened) {
      this.isSidebarOpen = opened
      this.$store.commit('setSidebarOpen', opened)
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'getError', 'currentTenant', 'currentUser']),
    locale() {
      return Vue.i18n.locale()
    }
  },
  watch: {
    getError: function(val) {
      this.toast(val, {
        type: 'error'
      })
    },
    locale: function(val) {
      if (val === 'ar') {
        this.rtl = true
      } else {
        this.rtl = false
      }
    },
    currentTenant() {
      if (this.currentTenant) {
        this.fetchProviders()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .inno-support {
    margin-left: 25px;
    margin-bottom: 25px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    inset-inline-start: 0;
    bottom: -40px;

    img {
      height: 100%;
      width: auto;
    }
  }
  .copyright-text {
    margin-left: auto;
    margin-right: auto;
  }
}
.pre-loader--container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
}
</style>
<style lang="scss">
.required {
  label:after {
    content: '\002A';
    color: $brand-danger;
  }
}
.toasted.vuestic-toast.error {
  background: rgba(231, 76, 60, 1);
  box-shadow: $toast-box-shadow;
  color: $toast-color;
  i,
  i.fa {
    color: $white;
  }
}
</style>
/* RTL STYLES */
<style lang="scss">
.rtl {
  direction: rtl;
  .form-group .control-label:not(.phone-label),
  .form-group input:valid ~ .control-label,
  .form-group input:focus ~ .control-label {
    left: unset;
    right: 0 !important;
  }
  .switch-label {
    margin-left: 45%;
  }
  .btn {
    letter-spacing: 1px;
  }
  .form-group.with-icon-left input,
  .form-group.with-icon-left textarea {
    padding-left: 0;
    padding-right: 1.375rem;
  }
  .form-group.with-icon-left input ~ .control-label {
    padding-right: 1.375rem;
  }
  .form-group.with-icon-left .icon-left {
    left: unset;
    right: 0;
  }
  .vuestic-dropdown {
    .dropdown-item {
      direction: rtl !important;
    }
  }
}
</style>
