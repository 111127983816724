import statsService from '../../../services/stats.service'

const state = {
  onlineExtensionsCount: 0,
  onlineExtensionsCountLoading: false,
  onlineExtensionsCountErrorMessage: '',
  onlineExtensionsCountError: null
}

const getters = {
  onlineExtensionsCount(state) {
    return state.onlineExtensionsCount
  },
  onlineExtensionsCountLoading(state) {
    return state.onlineExtensionsCountLoading
  },
  onlineExtensionsCountErrorMessage(state) {
    return state.onlineExtensionsCountErrorMessage
  },
  onlineExtensionsCountError(state) {
    return state.onlineExtensionsCountError
  }
}

const mutations = {
  setOnlineExtensionsCount(state, payload) {
    state.onlineExtensionsCount = payload
  },
  setOnlineExtensionsCountLoading(state, payload) {
    state.onlineExtensionsCountLoading = payload
  },
  setOnlineExtensionsCountErrorMessage(state, payload) {
    state.onlineExtensionsCountErrorMessage = payload
  },
  setOnlineExtensionsCountError(state, payload) {
    state.onlineExtensionsCountError = payload
  }
}

const actions = {
  async fetchOnlineExtensionsCount({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setOnlineExtensionsCountLoading', true)
      const onlineExtensionsCount = await statsService.getOnlineExtensionsCount()
      commit('setOnlineExtensionsCountErrorMessage', '')
      commit('setOnlineExtensionsCount', onlineExtensionsCount)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setOnlineExtensionsCountErrorMessage', errorMessage)
      commit('setOnlineExtensionsCountError', errorResponse)
    } finally {
      if (!silent) commit('setOnlineExtensionsCountLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
