<template>
  <div class="dropdown">
    <b-dropdown
      no-caret
      toggle-tag="div"
      variant="asdf"
      class="shadow-none"
      menu-class="shadow"
      :right="align === 'right'"
      :left="align === 'left'"
      :dropup="drop === 'up'"
      :dropleft="drop === 'left'"
      :dropright="drop === 'right'"
    >
      <template #button-content>
        <slot name="trigger"></slot>
      </template>
      <slot name="menu"></slot>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: () => 'left'
    },
    drop: {
      type: String,
      default: () => 'bottom'
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  .trigger {
    padding: 10px;
    cursor: pointer;
  }
  .dropdown-item {
    background-color: transparent;
    border-radius: 12px;
    color: black;
    &:hover {
      background-color: #ededed !important;
    }
  }
}
</style>
