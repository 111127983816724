import { api } from '../axios'
import store from '../store'

const getCDRs = async (filters = {}, page = 1, limit = 10) => {
  const filter = Object.keys(filters)
    .map(key => `${key}=${filters[key]}`)
    .join('&')

  const res = await api.get(
    `cdr/user/report?${filter}&page=${page}&limit=${limit}`
  )
  return res.data
}
const getAgentCDRs = async (filters = {}, page = 1, limit = 10) => {
  const filter = Object.keys(filters)
    .map(key => `${key}=${filters[key]}`)
    .join('&')

  const res = await api.get(
    `cdr/agent/report?${filter}&page=${page}&limit=${limit}`
  )
  return res.data
}

const exportCDRs = async filters => {
  const filter = Object.keys(filters)
    .map(key => `${key}=${filters[key]}`)
    .join('&')

  const res = await api.get(
    `cdr/user/export?${filter}&userEmail=${store.getters.currentUser.email}`
  )

  return res.data
}
const exportAgentCDRs = async filters => {
  const filter = Object.keys(filters)
    .map(key => `${key}=${filters[key]}`)
    .join('&')
  const res = await api.get(
    `cdr/agent/export?${filter}&userEmail=${store.getters.currentUser.email}`
  )

  return res.data
}

const getCDRRecord = async id => {
  const res = await api.get(`cdr/user/download-record/${id}`)
  return res.data
}
const getAgentCDRRecord = async id => {
  const res = await api.get(`cdr/agent/download-record/${id}`)
  return res.data
}

const submitCallSummary = async payload => {
  const res = await api.post('call-summary', payload)
  return res.data
}

const CDRService = {
  getCDRs,
  getAgentCDRs,
  exportCDRs,
  exportAgentCDRs,
  getCDRRecord,
  getAgentCDRRecord,
  submitCallSummary
}

export default CDRService
