<template>
  <div class="edit-contact-pad d-flex flex-column">
    <div class="d-flex align-items-center">
      <i
        class="fa p-3 back-btn"
        :class="dir === 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"
        @click="$emit('showContacts')"
      ></i>
      <h5 class="m-0 py-2">{{ $t('webrtc.update-contact.title') }}</h5>
    </div>
    <half-circle-spinner
      v-if="editContactLoading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <div class="m-auto text-danger" v-if="!!errorMessage">
      <p>{{ errorMessage }}</p>
    </div>
    <div
      class="edit-contact-form d-flex flex-column align-items-start p-1"
      v-if="!editContactLoading && !errorMessage"
    >
      <div class="form p-2 w-100">
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            class="mb-2"
            size="lg"
            variant="success"
            :text="avatarText"
          ></b-avatar>
          <b-form-input
            id="contactName"
            v-model="contact.name"
            required
            class="border mb-2"
            :placeholder="$t('webrtc.update-contact.form.name.placeholder')"
          ></b-form-input>
          <b-form-input
            id="contactPhone"
            v-model="contact.phone"
            required
            class="border mb-2"
            :placeholder="$t('webrtc.update-contact.form.phone.placeholder')"
          ></b-form-input>
          <button class="btn btn-primary btn-sm" @click="editContact()">
            {{ $t('webrtc.update-contact.form.update') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactService from '../../services/contact.service.js'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      contact: {},
      editContactLoading: false,
      errorMessage: ''
    }
  },
  props: {
    currentContact: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  mounted() {
    if (this.currentContact) this.contact = this.currentContact
  },
  computed: {
    avatarText() {
      if (!!this.contact.name) {
        const [firstPart, secondPart] = this.contact.name.split(' ')

        const firstLetter = firstPart ? firstPart.slice(0, 1) : ''
        const secondLetter = secondPart ? secondPart.slice(0, 1) : ''
        return firstLetter + secondLetter
      }
      return ''
    },
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  },
  methods: {
    async editContact() {
      try {
        this.editContactLoading = true
        const { id: contactId, ...contact } = this.contact
        await ContactService.editContact(contactId, contact)
        this.$emit('showContacts')
      } catch (error) {
        if (!!error.response) this.errorMessage = error.response.data.message
        else this.errorMessage = error.message
      } finally {
        this.editContactLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-contact-pad {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .back-btn {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
