import http from '../../axios'
import store from '../../store'

export default {
  state: {
    departments: [],
    products: [],
    articles: [],
    ticketSubmitted: false
  },
  getters: {
    departments(state) {
      return state.departments
    },
    products(state) {
      return state.products
    },
    articles(state) {
      return state.articles
    },
    ticketSubmitted(state) {
      return state.ticketSubmitted
    }
  },
  mutations: {
    setDepartments(state, departments) {
      state.departments = departments
    },
    setProducts(state, products) {
      state.products = products
    },
    setArticles(state, articles) {
      state.articles = articles
    },
    newTicketSubmitted(state, val) {
      state.ticketSubmitted = val
    }
  },
  actions: {
    async getDepartments({ commit }) {
      commit('setError', null)
      const response = await http.get(
        `${process.env.VUE_APP_NEW_API}zoho/departments`
      )
      commit('setDepartments', response.data)
      return {
        success: true,
        data: response.data
      }
    },
    async getProducts({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}zoho/products`
        )
        commit('setProducts', response.data)
        return {
          success: true,
          data: response.data
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async openTicket({ commit }, payload) {
      commit('setError', null)
      const formData = new FormData()
      let params = Object.keys(payload)

      params.forEach(param => {
        if (param === 'attachment' && payload[param].length == 1) {
          formData.append(param, payload[param][0])
        } else {
          formData.append(param, payload[param])
        }
      })
      const response = await http.post(
        `${process.env.VUE_APP_NEW_API}zoho/create-ticket`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      commit('newTicketSubmitted', true)
      return {
        success: true
      }
    },
    async fetchArticles({ commit }, filter) {
      commit('setError', null)
      try {
        const response = await http.get(
          `ticketing/api/v1/articles/all?filter=${filter ? filter : ''}`
        )
        if (response.data.data) {
          commit('setArticles', response.data.data)
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          return {
            success: true,
            data: []
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchArticleContent({ commit }, article_id) {
      try {
        const response = await http.get(
          `ticketing/api/v1/articles/${article_id}`
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchTickets(
      { commit },
      { filter, status, department, perPage, page }
    ) {
      commit('setError', null)
      try {
        const query = [`page=${page}`, `perPage=${perPage}`]
        if (department) query.push(`departmentId=${department}`)
        if (status) query.push(`status=${status}`)
        if (filter) query.push(`subject=${filter}`)
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}zoho/customer-tickets?${query.join(
            '&'
          )}`
        )

        return {
          success: true,
          data: response.data
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchTicketContent({ commit }, ticket_id) {
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}zoho/tickets/${ticket_id}`
        )
        return {
          success: true,
          data: response.data
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
