<template>
  <div class="web-rtc">
    <div class="floating d-flex flex-column align-items-end">
      <div v-show="isVirtualPhoneOpen">
        <phone @incomingCall="isVirtualPhoneOpen = true" ref="phone"></phone>
      </div>
      <button
        class="floating-btn p-3 rounded-circle btn btn-primary"
        @click="isVirtualPhoneOpen = !isVirtualPhoneOpen"
      >
        <img src="../assets/icons/dialpad.svg" alt="dialpad" />
      </button>
    </div>
  </div>
</template>

<script>
import Phone from '../elements/VirtualPhone/Phone'

export default {
  components: {
    Phone
  },
  data() {
    return {
      isVirtualPhoneOpen: false
    }
  },
  methods: {},
  mounted() {
    this.$root.$refs.phone = this.$refs.phone
  }
}
</script>

<style lang="scss" scoped>
.floating {
  position: fixed;
  bottom: 10px;
  right: 50px;
  width: max-content;
  z-index: 9999999;
  .floating-btn {
    img {
      width: 32px;
    }
  }
}
</style>
<style lang="scss" scoped>
.rtl {
  .floating {
    left: 50px;
  }
}
</style>
