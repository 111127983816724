import http from '../../axios'
import store from '../../store'

export default {
  state: {
    allBridges: []
  },
  getters: {
    allBridges(state) {
      return state.allBridges
    }
  },
  mutations: {
    addAllBridges(state, data) {
      state.allBridges = data
    }
  },
  actions: {
    async createBridge({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(
          `engine/bridge/api/v1/call/bridge/store`,
          {
            status: true,
            ...payload
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },

    async checkBridge({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.get(
          `engine/bridge/api/v1/call/bridge/${payload.id}`,
          {
            ...payload
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },

    async updateBridge({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.put(
          `engine/bridge/api/v1/call/bridge/${payload.call_uuid}`,
          {
            ...payload
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async deleteBridge({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.delete(
          `engine/bridge/api/v1/call/bridge/${payload}`
        )

        if (response.data.success) {
          return {
            success: true
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
