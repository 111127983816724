<template>
  <vuestic-sidebar :hidden="!isOpen">
    <template slot="menu">
      <sidebar-link :to="{ name: 'dashboard' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M23.9,11.437A12,12,0,0,0,0,13a11.878,11.878,0,0,0,3.759,8.712A4.84,4.84,0,0,0,7.113,23H16.88a4.994,4.994,0,0,0,3.509-1.429A11.944,11.944,0,0,0,23.9,11.437Zm-4.909,8.7A3,3,0,0,1,16.88,21H7.113a2.862,2.862,0,0,1-1.981-.741A9.9,9.9,0,0,1,2,13,10.014,10.014,0,0,1,5.338,5.543,9.881,9.881,0,0,1,11.986,3a10.553,10.553,0,0,1,1.174.066,9.994,9.994,0,0,1,5.831,17.076ZM7.807,17.285a1,1,0,0,1-1.4,1.43A8,8,0,0,1,12,5a8.072,8.072,0,0,1,1.143.081,1,1,0,0,1,.847,1.133.989.989,0,0,1-1.133.848,6,6,0,0,0-5.05,10.223Zm12.112-5.428A8.072,8.072,0,0,1,20,13a7.931,7.931,0,0,1-2.408,5.716,1,1,0,0,1-1.4-1.432,5.98,5.98,0,0,0,1.744-5.141,1,1,0,0,1,1.981-.286Zm-5.993.631a2.033,2.033,0,1,1-1.414-1.414l3.781-3.781a1,1,0,1,1,1.414,1.414Z"
              />
            </svg>
          </span>
          <span>{{ $t('sidebar.dashboard') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'call-reporting' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="m6.5,16H1C.448,16,0,15.552,0,15s.448-1,1-1h4.965l1.703-2.555c.197-.296.542-.473.894-.443.356.022.673.232.833.551l2.229,4.459,1.044-1.566c.186-.278.498-.445.832-.445h4.5c.552,0,1,.448,1,1s-.448,1-1,1h-3.965l-1.703,2.555c-.186.279-.499.445-.832.445-.021,0-.042,0-.062-.002-.356-.022-.673-.232-.833-.551l-2.229-4.459-1.044,1.566c-.186.278-.498.445-.832.445Zm15.5-5.515v8.515c0,2.757-2.243,5-5,5H7c-2.757,0-5-2.243-5-5,0-.552.448-1,1-1s1,.448,1,1c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3v-8.515c0-.163-.008-.325-.023-.485h-4.977c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023h-4.515c-1.654,0-3,1.346-3,3v6c0,.552-.448,1-1,1s-1-.448-1-1v-6C2,2.243,4.243,0,7,0h4.515c1.87,0,3.627.728,4.95,2.05l3.485,3.485c1.322,1.322,2.05,3.08,2.05,4.95Zm-2.659-2.485c-.218-.379-.487-.733-.805-1.05l-3.485-3.485c-.318-.318-.671-.587-1.05-.805v4.341c0,.551.449,1,1,1h4.341Z"
                />
              </svg>
            </span>
          </span>
          <span>{{ $t('sidebar.call-reporting') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link
        :to="{ name: 'auto-dialer' }"
        v-if="
          currentTenant.hasTenant &&
            ['6500900f57642a0382800f83', '6500904f57642a038280115a'].includes(
              currentTenant.id
            )
        "
      >
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="#000000"
              height="24px"
              width="24px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 56 56"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M12,31C5.383,31,0,36.383,0,43s5.383,12,12,12s12-5.383,12-12S18.617,31,12,31z M12,53C6.486,53,2,48.514,2,43   s4.486-10,10-10s10,4.486,10,10S17.514,53,12,53z"
                />
                <path
                  d="M18,42h-5v-5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1S18.553,42,18,42z"
                />
                <path
                  d="M55.421,41.904c-2.008-3.796-8.981-7.912-9.287-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68   c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.237,1.429-6.294-0.629L20.044,25.464c-2.045-2.045-2.233-3.928-0.631-6.291   c0.224-0.31,1.649-1.575,2.87-2.596c0.778-0.463,1.312-1.15,1.545-1.995c0.307-1.106,0.071-2.463-0.652-3.732   c-0.173-0.296-4.29-7.269-8.085-9.276C14.382,1.198,13.585,1,12.785,1c-1.319,0-2.558,0.514-3.49,1.445L6.758,4.982   c-4.014,4.013-5.467,8.562-4.321,13.52c0.743,3.213,2.608,6.589,5.529,10.065C9.252,28.207,10.601,28,12,28c8.271,0,15,6.729,15,15   c0,1.463-0.22,2.874-0.613,4.212c5.395,5.124,10.544,7.724,15.319,7.724c3.721,0,7.189-1.581,10.307-4.699l2.537-2.537   C56.09,46.159,56.441,43.83,55.421,41.904z"
                />
              </g>
            </svg>
          </span>
          <span>
            {{ $t('sidebar.auto-dialer') }}
          </span>
        </span>
      </sidebar-link>
    </template>
  </vuestic-sidebar>
</template>

<script>
import VuesticSidebar from '../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar'
import SidebarLink from '../admin/app-sidebar/components/SidebarLink'
import { mapGetters } from 'vuex'
export default {
  name: 'app-sidebar',
  components: {
    VuesticSidebar,
    SidebarLink
  },
  data() {
    return {
      canCreate: false
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentTenant', 'pbxUrl'])
  }
}
</script>
<style lang="scss">
.sidebar-link {
  a {
    .sidebar-menu-item-title {
      display: flex;
      align-items: center;
      .sidebar-menu-item-image {
        width: 30px;
        margin: 0 10px;
        text-align: center;
        @media (max-width: 1368px) {
          width: 24px;
        }
        svg {
          fill: $blue;
          @media (max-width: 1368px) {
            width: 15.51px;
          }
        }
        i {
          color: $blue;
        }
      }
    }
    &.router-link-exact-active,
    &:hover {
      .sidebar-menu-item-title {
        .sidebar-menu-item-image {
          svg {
            fill: $white;
          }
          i {
            color: $white;
          }
        }
      }
    }
  }
}
</style>
