<template>
  <div class="contacts">
    <half-circle-spinner
      v-if="loading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <fragment v-else>
      <crm-contacts-pad
        v-if="view === 'contacts-pad'"
        @showDialpad="$emit('showDialpad')"
        @callContact="$emit('callContact', $event)"
      >
      </crm-contacts-pad>
    </fragment>
  </div>
</template>

<script>
import ContactService from '../../../services/contact.service'
import CrmContactsPad from './CrmContactsPad.vue'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    CrmContactsPad,
    HalfCircleSpinner
  },
  data() {
    return {
      loading: false,
      view: 'contacts-pad',
      currentContact: {}
    }
  },
  mounted() {},
  methods: {
    onShowEditContactPad(currentContact) {
      this.currentContact = currentContact
      this.view = 'edit-contact-pad'
    },
    onShowContactProfilePad(currentContact) {
      this.currentContact = currentContact
      this.view = 'contact-profile-pad'
    },
    async deleteContact(contact) {
      try {
        this.loading = true
        await ContactService.deleteContact(contact.id)
      } catch (error) {
        if (!!error.response) this.contactsError = error.response.data.message
        else this.contactsError = error.message
      } finally {
        this.loading = false
        this.view = 'contacts-pad'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
