import JsSIP from 'jssip'
import CryptoJS from 'crypto-js'

const login = ({ uri, password }) => {
  process.env.NODE_ENV === 'production'
    ? JsSIP.debug.disable()
    : JsSIP.debug.enable('JsSIP:*')
  const socket = new JsSIP.WebSocketInterface(
    process.env.VUE_APP_SOCKET_INTERFACE
  )

  // decrypt password
  const passwordBytes = CryptoJS.AES.decrypt(
    password,
    process.env.VUE_APP_ENCRYPT_SECRET
  )
  const decryptedPassword = passwordBytes.toString(CryptoJS.enc.Utf8)

  const configuration = {
    sockets: [socket],
    uri,
    password: decryptedPassword
  }

  const ua = new JsSIP.UA(configuration)
  ua.start()

  return ua
}

export default {
  login
}
