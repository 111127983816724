import statsService from '../../../services/stats.service'

const state = {
  liveCallsCount: 0,
  liveCallsCountLoading: false,
  liveCallsCountErrorMessage: '',
  liveCallsCountError: null
}

const getters = {
  liveCallsCount(state) {
    return state.liveCallsCount
  },
  liveCallsCountLoading(state) {
    return state.liveCallsCountLoading
  },
  liveCallsCountErrorMessage(state) {
    return state.liveCallsCountErrorMessage
  },
  liveCallsCountError(state) {
    return state.liveCallsCountError
  }
}

const mutations = {
  setLiveCallsCount(state, payload) {
    state.liveCallsCount = payload
  },
  setLiveCallsCountLoading(state, payload) {
    state.liveCallsCountLoading = payload
  },
  setLiveCallsCountErrorMessage(state, payload) {
    state.liveCallsCountErrorMessage = payload
  },
  setLiveCallsCountError(state, payload) {
    state.liveCallsCountError = payload
  }
}

const actions = {
  async fetchLiveCallsCount({ commit }, payload = {}) {
    const { silent = false } = payload
    try {
      if (!silent) commit('setLiveCallsCountLoading', true)
      const liveCallsCount = await statsService.getLiveCallsCount()
      commit('setLiveCallsCountErrorMessage', '')
      commit('setLiveCallsCount', liveCallsCount)
    } catch (error) {
      const errorResponse = error.response.data
      const errorMessage = errorResponse.error || errorResponse.message
      commit('setLiveCallsCountErrorMessage', errorMessage)
      commit('setLiveCallsCountError', errorResponse)
    } finally {
      if (!silent) commit('setLiveCallsCountLoading', false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
