<template>
  <div class="keypad">
    <div class="no-gutters row">
      <div class="p-1 col-4" v-for="digit in digits" :key="digit.value">
        <button
          class="py-2 w-100 h-100 digit"
          @pointerdown="onPointerDown(() => onLongPress(digit))"
          @pointerup="onPointerup(() => onDigitPress(digit))"
        >
          <h2>{{ digit.key }}</h2>
          <p class="text-muted">
            <small>{{ digit.keyNote }}</small>
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { digits } from '../../constants/dialpad'
import { LongPress } from '../../utils/longPress'

let longPress = new LongPress(300)

export default {
  data() {
    return {
      digits
    }
  },
  methods: {
    onDigitPress(digit) {
      var audio = new Audio(digit.tone)
      audio.play()
      this.$emit('digitPress', digit)
    },
    onPointerDown(callback) {
      longPress.start(callback)
    },
    onPointerup(callback) {
      if (!longPress.stop()) {
        callback()
      }
    },
    onLongPress(digit) {
      if (digit.value === '0') {
        this.onDigitPress({
          ...digit,
          value: digit.keyNote
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.keypad {
  button.digit {
    border: none;
    background: #fff;
    border-radius: 12px;
    outline: none;

    &:hover {
      background: #ededed;
    }
  }
  p,
  h2 {
    margin: 0;
  }

  p small:empty::before {
    content: '';
    display: inline-block;
  }
}
</style>
