<template>
  <div class="field">
    <div class="head">
      <label v-if="!!label" for="" class="m-0">{{ label }}</label>
      <span v-if="!!info" :id="`field-info-${uid}`">
        <i class="fa fa-info-circle"></i>
        <b-tooltip :target="`field-info-${uid}`" triggers="hover">
          {{ info }}
        </b-tooltip>
      </span>
    </div>
    <slot>
      <div class="position-relative">
        <b-form-input
          :type="type === 'password' && passwordVisible ? 'text' : type"
          class="border"
          :placeholder="placeholder"
          :value="value"
          @input="onChange"
          :class="{ 'border-danger': !!error }"
          :disabled="disabled"
        ></b-form-input>
        <i
          v-if="type === 'password'"
          class="position-absolute fa toggle-password-icon"
          :class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
          @click="togglePassword"
        ></i>
      </div>
    </slot>
    <div class="d-flex flex-column">
      <span v-if="!!error" class="text-danger">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'text'
    },
    value: {
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    info: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      passwordVisible: false,
      uid: Math.random()
        .toString(36)
        .substring(7)
    }
  },
  methods: {
    togglePassword() {
      this.passwordVisible = !this.passwordVisible
    },
    onChange($event) {
      if (this.type === 'number') {
        this.$emit('input', +$event)
      } else {
        this.$emit('input', $event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-password-icon {
  top: 8px;
  right: 15px;
  cursor: pointer;
}
.head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>

<style lang="scss">
.rtl {
  .toggle-password-icon {
    right: initial;
    left: 15px;
  }
}
</style>
