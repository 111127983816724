import { api } from '../axios'

const getCallDistribution = async () => {
  const res = await api.get('statistics/call-distribution')
  const callDistribution = res.data.data.callDistribution

  const labels = Array.from(
    new Set(callDistribution.incoming.labels, callDistribution.outgoing.labels)
  )
  const datasets = Object.keys(callDistribution).map(key => ({
    key,
    label: key,
    data: callDistribution[key].values
  }))
  return {
    config: {
      outgoing: {
        backgroundColor: '#008fd185'
      },
      incoming: {
        backgroundColor: '#12632785'
      }
    },
    labels,
    datasets
  }
}
const getAnsweredCalls = async () => {
  const res = await api.get('statistics/tenant-answered-calls')
  const totalCalls = res.data.data.totalCalls

  const labels = totalCalls.labels
  const datasets = totalCalls.values

  return { labels, datasets }
}

const getExtensionsCount = async () => {
  const res = await api.get('extension/count')
  return res.data
}
const getOnlineExtensionsCount = async () => {
  const res = await api.get('extension/online-count')
  return res.data
}

const getLiveCallsCount = async () => {
  const res = await api.get('extension/live-calls-count')
  return res.data
}
const getAverageCallDuration = async () => {
  const res = await api.get('statistics/calls-average-duration')
  return res.data.averageDuration
}
const getTodayCallDuration = async () => {
  const userType = localStorage.getItem('userType')
  const res = await api.get(`cdr/${userType}/today`)
  return res.data.allDay
}
const getLastHourCallDuration = async () => {
  const userType = localStorage.getItem('userType')
  const res = await api.get(`cdr/${userType}/today`)
  return res.data.lastHourOfDay
}

const getBalance = async () => {
  const res = await api.get(`jera/client-balance`)
  return res.data
}
const getUsage = async () => {
  const day = new Date().toJSON().slice(0, 10)
  const res = await api.get(`jera/usage?day=${day}`)
  return res.data
}
const getOverdueInvoices = async () => {
  const res = await api.get(`zoho/overdue-invoices`)
  return res.data
}

const getAgentStats = async () => {
  const res = await api.get('cdr/agent/today')
  return res.data
}

const statsService = {
  getCallDistribution,
  getAnsweredCalls,
  getAgentStats,
  getLiveCallsCount,
  getAverageCallDuration,
  getTodayCallDuration,
  getLastHourCallDuration,
  getExtensionsCount,
  getOnlineExtensionsCount,
  getBalance,
  getUsage,
  getOverdueInvoices
}

export default statsService
