<template>
  <div class="contacts">
    <half-circle-spinner
      v-if="loading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <fragment v-else>
      <contacts-pad
        v-if="view === 'contacts-pad'"
        @showDialpad="$emit('showDialpad')"
        @callContact="$emit('callContact', $event)"
        @showContact="onShowContactProfilePad($event)"
        @createContact="view = 'create-contact-pad'"
        @editContact="onShowEditContactPad($event)"
        @deleteContact="deleteContact($event)"
      >
      </contacts-pad>
      <contact-profile-pad
        v-if="view === 'contact-profile-pad'"
        :contact="currentContact"
        @showContacts="view = 'contacts-pad'"
        @callContact="$emit('callContact', $event)"
        @editContact="onShowEditContactPad($event)"
        @deleteContact="deleteContact($event)"
      >
      </contact-profile-pad>
      <create-contact-pad
        v-if="view === 'create-contact-pad'"
        @showContacts="view = 'contacts-pad'"
      >
      </create-contact-pad>
      <edit-contact-pad
        v-if="view === 'edit-contact-pad'"
        @showContacts="view = 'contacts-pad'"
        :currentContact="currentContact"
      >
      </edit-contact-pad>
    </fragment>
  </div>
</template>

<script>
import ContactService from '../../services/contact.service'
import ContactProfilePad from './ContactProfilePad.vue'
import ContactsPad from './ContactsPad.vue'
import CreateContactPad from './CreateContactPad.vue'
import EditContactPad from './EditContactPad.vue'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    ContactsPad,
    CreateContactPad,
    EditContactPad,
    ContactProfilePad,
    HalfCircleSpinner
  },
  data() {
    return {
      loading: false,
      view: 'contacts-pad',
      currentContact: {}
    }
  },
  mounted() {},
  methods: {
    onShowEditContactPad(currentContact) {
      this.currentContact = currentContact
      this.view = 'edit-contact-pad'
    },
    onShowContactProfilePad(currentContact) {
      this.currentContact = currentContact
      this.view = 'contact-profile-pad'
    },
    async deleteContact(contact) {
      try {
        this.loading = true
        await ContactService.deleteContact(contact.id)
      } catch (error) {
        if (!!error.response) this.contactsError = error.response.data.message
        else this.contactsError = error.message
      } finally {
        this.loading = false
        this.view = 'contacts-pad'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
