<template>
  <div class="contacts-pad d-flex flex-column" @scroll="onContactsScroll">
    <half-circle-spinner
      v-if="isContactsLoading"
      :animation-duration="1500"
      :size="25"
      color="#74b9ff"
      class="m-auto"
    />
    <div class="m-auto text-danger" v-if="!!contactsError">
      <p>{{ contactsError }}</p>
    </div>
    <div
      class="contacts d-flex flex-column align-items-start p-1"
      v-if="!isContactsLoading && !contactsError"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <i
            class="fa p-3 back-btn"
            :class="dir === 'rtl' ? 'fa-angle-right' : 'fa-angle-left'"
            @click="$emit('showDialpad')"
          ></i>
          <h4 class="m-0 py-2">{{ $t('webrtc.crm-contacts.title') }}</h4>
        </div>
      </div>
      <div
        class="contact p-2 w-100"
        :class="!contact.phone ? 'disabled' : ''"
        :title="!contact.phone ? $t('webrtc.contacts.errors.no-phone') : ''"
        v-for="contact in contacts"
        :key="contact.id"
      >
        <div class="d-flex align-items-center w-100 text-left">
          <b-avatar
            variant="success"
            :text="contactAvatarText(contact)"
          ></b-avatar>
          <div
            class="flex-fill d-flex justify-content-between align-items-center"
          >
            <p class="name mx-3 mb-0" :title="contact.name || contact.phone">
              <b>{{ contact.name || contact.phone }}</b>
            </p>

            <div class="actions d-flex align-items-center">
              <button
                class="btn btn-primary btn-sm shadow-none mx-2 px-3 py-2"
                @click="onCall(contact)"
              >
                <i class="fa fa-phone" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <half-circle-spinner
        v-if="isLoadingMore"
        :animation-duration="1500"
        :size="25"
        color="#74b9ff"
        class="m-auto"
      />
    </div>
  </div>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import CrmService from '../../../services/crm.service'

export default {
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      contacts: [],
      isContactsLoading: false,
      isLoadingMore: false,
      contactsError: '',
      page: 1,
      hasNext: false
    }
  },
  mounted() {
    this.getCrmContacts()
  },
  methods: {
    async getCrmContacts() {
      try {
        this.isContactsLoading = true
        const { contacts, hasNext } = await CrmService.getCrmContacts(this.page)
        this.contacts = contacts
        this.hasNext = hasNext
      } catch (error) {
        if (!!error.response) this.contactsError = error.response.data.message
        else this.contactsError = error.message
      } finally {
        this.isContactsLoading = false
      }
    },
    contactAvatarText(contact) {
      if (!!contact.name) {
        const [firstPart, secondPart] = contact.name.split(' ')

        const firstLetter = firstPart ? firstPart.slice(0, 1) : ''
        const secondLetter = secondPart ? secondPart.slice(0, 1) : ''
        return firstLetter + secondLetter
      }
      return ''
    },
    async onContactsScroll(event) {
      const element = event.target
      const offset = 50
      const totalHeight = element.scrollHeight - element.offsetHeight - offset

      const isAtBottom = element.scrollTop >= totalHeight

      if (isAtBottom && !this.isLoadingMore && this.hasNext) {
        try {
          this.isLoadingMore = true
          this.page += 1
          const { contacts, hasNext } = await CrmService.getCrmContacts(
            this.page
          )
          this.contacts = [...this.contacts, ...contacts]
          this.hasNext = hasNext
        } catch (error) {
          console.log(error)
        } finally {
          this.isLoadingMore = false
        }
      }
    },
    onCall(contact) {
      if (!contact.phone) {
        this.toast(this.$t('webrtc.contacts.errors.no-phone'), {
          type: 'error'
        })
      } else {
        this.$emit('callContact', contact)
      }
    }
  },
  computed: {
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-pad {
  min-height: inherit;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  .back-btn {
    cursor: pointer;
    border-radius: 12px;
    &:hover {
      background: #ededed;
    }
  }

  .contact {
    cursor: pointer;
    border-radius: 12px;
    margin: 4px 0;
    &:hover {
      background: #ededed;
    }

    &.disabled {
      opacity: 0.5;
      background: #ededed;
      cursor: not-allowed;
    }

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 65px;
      overflow: hidden;
      text-align: start;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 12px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
