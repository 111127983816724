export default {
  itemsPerPage: [
    {
      value: 10
    },
    {
      value: 25
    },
    {
      value: 100
    }
  ]
}
