const state = {
  sidebar: {
    opened: false,
    withoutAnimation: false
  },
  config: {
    googleMaps: {
      apiKey: 'AIzaSyBNAqPrTQoz9P4NBlDDyfxrnKiafkaL8iQ'
    },
    windowMatchSizeLg: '(min-width: 992px)',
    palette: {
      primary: '#008ecf',
      danger: '#FC476A',
      info: '#0D4D80',
      success: '#22B573',
      warning: '#f7cc36',
      white: '#fff',
      black: '#000',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd'
    }
  },
  isLoading: true,
  error: null,
  popupOpen: false,
  filterbarOpen: false,
  reloadTabledata: false,
  sidebarOpen: true
}

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setError(state, error) {
    state.error = error
  },
  setPopupOpen(state, val) {
    state.popupOpen = val
  },
  setFilterbarOpen(state, val) {
    state.filterbarOpen = val
  },
  setReloadTabledata(state, val) {
    state.reloadTabledata = val
  },
  setSidebarOpen(state, val) {
    state.sidebarOpen = val
  },
  TOGGLE_WITHOUT_ANIMATION(state, value) {
    state.sidebar.withoutAnimation = value
  }
}
const getters = {
  getError(state) {
    return state.error
  },
  popupOpen(state) {
    return state.popupOpen
  },
  filterbarOpen(state) {
    return state.filterbarOpen
  },
  reloadTabledata(state) {
    return state.reloadTabledata
  },
  sidebarOpen(state) {
    return state.sidebarOpen
  }
}
const actions = {
  isToggleWithoutAnimation({ commit }, value) {
    commit('TOGGLE_WITHOUT_ANIMATION', value)
  },
  togglePopup({ commit }, value) {
    commit('setPopupOpen', value)
  },
  toggleFilterbar({ commit }, value) {
    commit('setFilterbarOpen', value)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
