// utils/longPress.js
export class LongPress {
  constructor(duration = 400) {
    this.duration = duration
    this.timer = null
    this.isLongPress = false
  }

  start(callback) {
    this.timer = setTimeout(() => {
      callback()
      this.isLongPress = true
    }, this.duration)
  }

  stop() {
    clearTimeout(this.timer)
    const wasLongPress = this.isLongPress
    this.reset()
    return wasLongPress
  }

  reset() {
    this.isLongPress = false
  }
}
