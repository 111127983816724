import http from '../../axios'
export default {
  state: {
    DateFrom: '',
    DateTo: '',
    SelectedExtentions: []
  },
  getters: {
    DateFrom(state) {
      return state.DateFrom
    },
    DateTo(state) {
      return state.DateTo
    },
    SelectedExtentions(state) {
      return state.SelectedExtentions
    }
  },
  mutations: {
    setDateFrom(state, payload) {
      state.DateFrom = payload
    },
    setDateTo(state, payload) {
      state.DateTo = payload
    },
    setSelectedExtentions(state, payload) {
      state.SelectedExtentions = payload
    }
  },
  actions: {
    async createSurveyHandler({ commit }, payload) {
      commit('setError', null)
      try {
        // const formData = new FormData()
        // let params = Object.keys(payload)
        // params.forEach(param => {
        //   if (param === 'questions') {
        //     for (let i = 0; i < payload[param].length; i++) {
        //       if (payload[param][i].agent_score) {
        //         formData.append(
        //           'questions[' + i + '][agent_score]',
        //           payload[param][i].agent_score
        //         )
        //       }
        //       formData.append(
        //         'questions[' + i + '][sound_file]',
        //         payload[param][i].sound_file
        //       )
        //       formData.append(
        //         'questions[' + i + '][question_type_id]',
        //         payload[param][i].question_type_id
        //       )
        //     }
        //   } else {
        //     formData.append(param, payload[param])
        //   }
        // })
        const response = await http.post('call/survey/api/v1/survey', payload)
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
