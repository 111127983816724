import http from '../../axios'
export default {
  actions: {
    async createOrder({ commit }, payload) {
      commit('setError', null)
      try {
        const formData = new FormData()
        let params = Object.keys(payload)
        params.forEach(param => {
          if (param === 'file') {
            formData.append(param, payload[param][0])
          } else if (param === 'retries') {
            formData.append(param, JSON.stringify(payload[param]))
          } else {
            formData.append(param, payload[param])
          }
        })
        const response = await http.post(
          'ecommercewa/api/v1/order/confirmation/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async updateBatch({ commit }, payload) {
      commit('setError', null)
      try {
        const formData = new FormData()
        let params = Object.keys(payload)
        params.forEach(param => {
          if (param === 'file') {
            formData.append(param, payload[param][0])
          } else if (param === 'retries') {
            formData.append(param, JSON.stringify(payload[param]))
          } else {
            formData.append(param, payload[param])
          }
        })
        formData.append('_method', 'PUT')
        const response = await http.post(
          `ecommercewa/api/v1/order/confirmation/upload/${payload.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
