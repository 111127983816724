import http from '../../axios'
import store from '../../store'

export default {
  state: {
    providers: {}
  },
  getters: {
    providers(state) {
      return state.providers
    }
  },
  mutations: {
    setProviders(state, val) {
      state.providers = val
    }
  },
  actions: {
    async fetchProviders({ commit, rootState }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}jera/payment/providers`
        )
        commit('setProviders', response.data)
        return {
          success: true,
          data: { ...response.data }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
