import http from '../../axios'

export default {
  actions: {
    async createDial({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(`auto-dial/api/v1/dial`, {
          ...payload
        })
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },

    async updateDial({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.put(`auto-dial/api/v1/dial/${payload.id}`, {
          ...payload
        })
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    }
  }
}
