export default {
  methods: {
    onCopySuccess() {
      this.toast(this.$t('shared.copySuccess'))
    },
    onCopyError(error) {
      console.error('Failed to copy: ', error)
      this.toast(this.$t('shared.copyFailed'), { type: 'error' })
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        // Use navigator.clipboard API if available
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.onCopySuccess()
          })
          .catch(error => {
            this.onCopyError(error)
          })
      } else {
        // Fallback for older browsers
        let textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          let successful = document.execCommand('copy')
          if (successful) {
            this.onCopySuccess()
          } else {
            this.onCopyError(error)
          }
        } catch (err) {
          this.onCopyError(error)
        }
        document.body.removeChild(textArea)
      }
    }
  }
}
