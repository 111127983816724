<template>
  <div class="">
    <div class="field d-flex flex-column w-full mb-4">
      <label>{{ $t('phone.summary-modal.form.comment.label') }}</label>
      <textarea
        class="border"
        rows="5"
        :placeholder="$t('phone.summary-modal.form.comment.placeholder')"
        v-model="postCallData.comment"
      ></textarea>
      <span class="error text-danger" v-if="summaryFormErrors.comment">
        {{ summaryFormErrors.comment }}
      </span>
    </div>
    <div class="field d-flex flex-column w-full mb-4">
      <label>
        {{ $t('phone.summary-modal.form.tags.label') }}
      </label>
      <Select
        searchable
        multiple
        trackBy="name"
        :preselectFirst="false"
        :placeholder="$t('phone.summary-modal.form.tags.placeholder')"
        :options="tagsOptions"
        v-model="postCallData.postCallTags"
      >
      </Select>
      <span class="error text-danger" v-if="summaryFormErrors.postCallTags">
        {{ summaryFormErrors.postCallTags }}
      </span>
    </div>

    <b-button @click="submitCallSummary">
      {{ $t('phone.summary-modal.form.submit') }}
    </b-button>
  </div>
</template>

<script>
import { object, string, array } from 'yup'
import { yupToKV } from '../../utils/yup'
import tagService from '../../services/tags.service'

export default {
  data() {
    return {
      postCallData: {
        comment: '',
        postCallTags: []
      },
      tagsOptions: [],
      summaryFormErrors: {}
    }
  },
  methods: {
    async submitCallSummary() {
      this.summaryFormErrors = {}

      const schema = object().shape({
        comment: string()
          .trim()
          .required(this.$t('phone.summary-modal.form.comment.error.required')),
        postCallTags: array().min(
          1,
          this.$t('phone.summary-modal.form.tags.error.required')
        )
      })

      const data = {
        ...this.postCallData,
        postCallTags: this.postCallData.postCallTags.map(tag => tag.id)
      }

      await schema.validate(data, { abortEarly: false }).catch(err => {
        this.summaryFormErrors = yupToKV(err)
      })

      if (!schema.isValidSync(data)) {
        return
      }

      this.$emit('submitCallSummary', data)
    },
    async fetchTagsOptions() {
      const res = await tagService.getAgentAvailableTags()
      this.tagsOptions = res.callTags
    }
  },
  mounted() {
    this.fetchTagsOptions()
  }
}
</script>
