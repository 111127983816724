import Toasted from 'vue-toasted'
import Vue from 'vue'

const toastedOptions = {
  theme: 'none',
  position: 'top-right',
  className: 'vuestic-toast',
  iconPack: 'fontawesome',
  duration: 4000,
  fullWidth: false
}

Vue.use(Toasted, toastedOptions)

export default {
  methods: {
    toast(message, options) {
      const { type, ...toastOptions } = {
        type: 'success',
        ...options
      }

      if (type === 'error') {
        this.$toasted.error(message, {
          icon: 'fa-times',
          ...toastOptions
        })
      } else {
        this.$toasted.show(message, {
          icon: 'fa-check',
          ...toastOptions
        })
      }
    }
  }
}
