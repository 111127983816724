import AuthLayout from '../../components/auth/AuthLayout'

const authRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    redirect: { name: 'login' },
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../../components/auth/login/Login')
      },
      {
        name: 'account',
        path: 'account',
        component: () => import('../../components/auth/login/Account')
      },
      {
        name: 'forget-password',
        path: 'forget-password',
        component: () =>
          import('../../components/auth/forget-password/forget-password')
      },
      {
        name: 'pin',
        path: 'pin',
        component: () => import('../../components/auth/forget-password/pin')
      },
      {
        name: 'reset-password',
        path: 'reset-password/:token',
        component: () =>
          import('../../components/auth/forget-password/reset-password')
      },
      {
        name: 'demo-register',
        path: 'demo/register',
        component: () => import('../../components/auth/demo/DemoRegister')
      }
    ]
  }
]

export default authRoutes
